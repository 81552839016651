import { Close } from '@mui/icons-material';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { languageAtom } from '../../../state/controls';
import { collection, onSnapshot, query, QueryConstraint, Unsubscribe } from '@firebase/firestore';
import { firestore } from '../../../firebase';
import { isString } from 'lodash';
import './ListPage.scss';
import { PageLoader } from '../PageLoader/PageLoader';

interface ListPageProps {
    renderElement: (element: Record<string, unknown>, highlight: string) => JSX.Element;
    actionText: string;
    onActionBtnClick: () => void;
    collectionName: string;
    queryConstraint?: QueryConstraint;
    actionAllowed?: boolean;
}

const ListPage: FC<ListPageProps> = ({
    actionText,
    onActionBtnClick,
    actionAllowed,
    collectionName,
    queryConstraint,
    renderElement,
}) => {
    const [{ lang }] = useAtom(languageAtom);
    const [isFetching, setIsFetching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [elements, setElements] = useState<Record<string, unknown>[]>([]);
    const unsubscribe = useRef<Unsubscribe>();

    const fetchElements = useCallback(async () => {
        setIsFetching(true);
        const q = queryConstraint
            ? query(collection(firestore, collectionName), queryConstraint)
            : query(collection(firestore, collectionName));

        unsubscribe.current = onSnapshot(q, (querySnapshot) => {
            const docs: Record<string, unknown>[] = [];

            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id } as Record<string, unknown>);
            });

            setElements(docs);
            setIsFetching(false);
        });
    }, [collectionName, queryConstraint]);

    const filterElements = (element: Record<string, unknown>) =>
        Object.keys(element).some((key) => {
            const value = element[key];
            return isString(value) && value.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
        });

    useEffect(() => {
        fetchElements();
        return () => {
            unsubscribe.current?.();
        };
    }, [fetchElements]);

    const renderActions = () => (
        <div className='actions'>
            <div className='search'>
                <TextField
                    label={lang.search}
                    variant='standard'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(`${e.target.value}`)}
                />
                <IconButton onClick={() => setSearchTerm('')} className={classNames('clear-search', { hidden: !searchTerm })}>
                    <Close />
                </IconButton>
            </div>
            {actionAllowed ? (
                <Button variant='contained' color='secondary' className='add-btn' onClick={onActionBtnClick}>
                    {actionText}
                </Button>
            ) : null}
        </div>
    );

    const renderElements = () => {
        return elements?.length ? (
            elements.filter(filterElements).map((filtered, ix) => (
                <Grid item key={ix}>
                    {renderElement(filtered, searchTerm)}
                </Grid>
            ))
        ) : (
            <span className='no-elements'>No elements added yet</span>
        );
    };

    return (
        <div className='elements'>
            {!isFetching ? (
                <Grid container spacing={{ xs: 2 }}>
                    {renderActions()}
                    {renderElements()}
                </Grid>
            ) : (
                <PageLoader />
            )}
        </div>
    );
};

export { ListPage };
