import { atom } from 'jotai';

export type RoleData = {
  role: string;
  fbId: string;
  uid: string;
  phone: string;
};

export type UserData = Record<string, unknown> & RoleData;

const authInitializedAtom = atom(false);
const userDataAtom = atom<UserData | null>(null);

export { authInitializedAtom, userDataAtom };
