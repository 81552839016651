import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { LangKeys, languageAtom } from '../../state/controls';

import { useUserRole } from '../../hooks/useUserRole';
import { Link } from 'react-router-dom';
import { collection, documentId, getDocs, query, where } from '@firebase/firestore';
import { firestore } from '../../firebase';
import { Offer } from '../Customers/Customers';
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { Currency } from '../../components/common/ProfilePage/ProfilePage';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { ImageNotSupported, LocalOfferOutlined } from '@mui/icons-material';
import { Developer } from '../DeveloperProfile/DeveloperProfile';
import { Unit } from '../UnitProfile/UnitProfile';
import './Offers.scss';
import { Project } from '../Projects/Projects';

const Offers: FC = () => {
  const [{ lang }] = useAtom(languageAtom);
  const { fbId } = useUserRole();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [developers, setDevelopers] = useState<Developer[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getRealtorOffers = useCallback(async () => {
    setIsFetching(true);
    const offersQuery = query(collection(firestore, 'offers'), where('realtorId', '==', fbId));
    const offers = await (await getDocs(offersQuery)).docs;

    const realtorOffers = offers.map((offer) => ({ ...(offer.data() as Offer), id: offer.id }));

    const developersIds = realtorOffers.map((offer) => offer.developerId);
    const unitIds = realtorOffers.map((offer) => offer.unitId);
    const projectIds = realtorOffers.map((offer) => offer.projectId);

    const developersQuery =
      developersIds?.length && query(collection(firestore, 'developers'), where(documentId(), 'in', developersIds));
    const developersResult = developersQuery && (await (await getDocs(developersQuery)).docs);

    const unitsQuery = unitIds?.length && query(collection(firestore, 'units'), where(documentId(), 'in', unitIds));
    const unitsResult = unitsQuery && (await (await getDocs(unitsQuery)).docs);

    const projectsQuery =
      projectIds?.length && query(collection(firestore, 'projects'), where(documentId(), 'in', projectIds));
    const projectsResult = projectsQuery && (await (await getDocs(projectsQuery)).docs);

    projectsResult &&
      setProjects(projectsResult.map((project) => ({ ...(project.data() as Project), id: project.id })));
    developersResult &&
      setDevelopers(developersResult.map((developer) => ({ ...developer.data(), id: developer.id } as Developer)));
    unitsResult && setUnits(unitsResult.map((unit) => ({ ...unit.data(), id: unit.id } as Unit)));

    setOffers(realtorOffers);
    setIsFetching(false);
  }, [fbId]);

  useEffect(() => {
    getRealtorOffers();
  }, [getRealtorOffers]);

  const renderDeveloper = (developerId: string) => {
    const developerData = developers.find((dev) => dev.id === developerId);

    return (
      <Link to={`/developers/${developerData?.id}`} style={{ textDecoration: 'none' }}>
        <Card className='card' elevation={3} sx={{ width: '150px', height: '270px' }}>
          <CardContent>
            <Typography variant='overline'>{lang.developer}</Typography>
          </CardContent>
          {developerData?.logo.length ? (
            <CardMedia component='img' sx={{ width: 150, height: 150 }} image={developerData?.logo?.[0]?.url} />
          ) : (
            <div className='no-image' />
          )}
          <CardContent>
            <Typography variant='subtitle2'>{developerData?.name}</Typography>
          </CardContent>
        </Card>
      </Link>
    );
  };

  const renderUnit = (unitId: string) => {
    const unitData = units.find((u) => u.id === unitId);
    const parentProject = projects.find((p) => p.id === unitData?.projectId);

    return (
      <Link to={`/units/${unitData?.id}`} style={{ textDecoration: 'none' }}>
        <Card className='card' elevation={3} sx={{ width: '150px', height: '270px' }}>
          <CardContent>
            <Typography variant='overline'>{lang.unit}</Typography>
          </CardContent>
          {unitData?.pics?.length ? (
            <CardMedia component='img' sx={{ width: 150, height: 150 }} image={unitData.pics[0].url} />
          ) : (
            <div className='no-image'>
              <ImageNotSupported className='no-img-icon' />
            </div>
          )}
          <CardContent>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='subtitle2'>{lang.project}:</Typography>
              <Link to={`/projects/${parentProject?.id}`}>{parentProject?.name}</Link>
            </Stack>
          </CardContent>
        </Card>
      </Link>
    );
  };

  const renderOffer = (offer: Offer) => {
    return (
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={3}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              <Stack alignItems='center' direction='row' spacing={1}>
                <LocalOfferOutlined />
                <span>{lang.price_offer}:</span>
                <span>{Currency[offer.currency as LangKeys]}</span>
                <span>{offer.priceOffer}</span>
              </Stack>
            </Typography>
            {offer.description && (
              <Typography gutterBottom variant='subtitle1' component='div'>
                <Stack direction='row' spacing={1}>
                  <span>{lang.description}:</span>
                  <span>{offer.description}</span>
                </Stack>
              </Typography>
            )}

            <div className='cards'>
              {renderDeveloper(offer.developerId)}
              {renderUnit(offer.unitId)}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderContent = () => {
    return offers.length ? (
      <Grid container spacing={1}>
        {offers.map(renderOffer)}
      </Grid>
    ) : (
      lang.no_offers
    );
  };

  return <div className='offers'>{!isFetching ? renderContent() : <PageLoader />}</div>;
};

export { Offers };
