import smallLogo from './assets/small-logo.png';
import sparkles from './assets/sparkles.png';
import React, { FC, useCallback, useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { auth } from './firebase';

import { useAtom } from 'jotai';
import { activeRouteAtom } from './state/route';
import { LangKeys, languageAtom, switchLanguageAtom } from './state/controls';

import {
  Close,
  Work,
  Construction,
  Business,
  AccountTree,
  Logout,
  Menu,
  Dashboard,
  People,
  LocalOffer,
  QrCode2,
} from '@mui/icons-material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { ListItemButton, ListItemIcon, ListItemText, Button, Stack, Fade, ListItem, Avatar } from '@mui/material';
import { startCase } from 'lodash';
import { Routes } from './AppRoutes';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useUserRole } from './hooks/useUserRole';
import { authInitializedAtom, UserData, userDataAtom } from './state/auth';
import { UploadedFile } from './components/common/FormModal/FormModal';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.standard,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.standard,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AppContent: FC = () => {
  const [userData, setUserData] = useAtom(userDataAtom);
  const [, setAuthInitialized] = useAtom(authInitializedAtom);
  const [{ lang, rtlOn }] = useAtom(languageAtom);
  const [, switchLanguage] = useAtom(switchLanguageAtom);
  const [activeRoute, setActiveRoute] = useAtom(activeRouteAtom);
  const [open, setOpen] = React.useState(false);
  const { replace } = useHistory();
  const { isAdmin, isDeveloper, isCustomer, isRealtor } = useUserRole();

  const navigate = useCallback(
    (url: string) => {
      replace(`/${url}`);
      setActiveRoute(url);
    },
    [setActiveRoute, replace]
  );

  const logout = useCallback(async () => {
    await auth.signOut();
    setUserData(null);
    setAuthInitialized(false);
    replace(`/login`);
  }, [replace, setUserData, setAuthInitialized]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const items = ['dashboard'];

  if (isAdmin) {
    items.push('projects', 'developers', 'realtors', 'networks');
  } else if (isDeveloper) {
    items.push('projects', 'realtors', 'networks');
  } else if (isRealtor) {
    items.push('projects', 'customers', 'offers');
  } else if (isCustomer) {
    items.push('scans');
  }

  const icons: Record<string, JSX.Element> = useMemo(() => {
    const iconProps = { className: 'sidebar-icon' };
    return {
      dashboard: <Dashboard {...iconProps} />,
      projects: <Work {...iconProps} />,
      developers: <Construction {...iconProps} />,
      realtors: <Business {...iconProps} />,
      networks: <AccountTree {...iconProps} />,
      customers: <People {...iconProps} />,
      offers: <LocalOffer {...iconProps} />,
      scans: <QrCode2 {...iconProps} />,
    };
  }, []);

  const renderIcon = (name: string) => {
    const Icon = () => icons[name];

    return <Icon />;
  };

  const { pics, email } = (userData || {}) as UserData & { pics: UploadedFile[]; email: string };

  return (
    <Box dir={rtlOn ? 'rtl' : 'ltr'} className='main'>
      <CssBaseline />
      <AppBar className='appBar' position='fixed' open={open}>
        <Toolbar>
          {(isAdmin || isDeveloper || isCustomer || isRealtor) && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <Menu />
            </IconButton>
          )}
          {auth.currentUser === null && <Button onClick={logout}>{lang.log_in}</Button>}
          <Typography variant='h6' noWrap component='div'>
            {lang[activeRoute as LangKeys]}
          </Typography>
          <IconButton className={classNames('lang-switcher', { rtl: rtlOn })} onClick={switchLanguage}>
            {lang.lang}
          </IconButton>
        </Toolbar>
      </AppBar>
      {auth.currentUser !== null ? (
        <Drawer PaperProps={{ className: 'sidebar' }} variant='permanent' open={open}>
          <DrawerHeader className='sidebar-header'>
            <img className='logo' src={smallLogo} />
            <IconButton onClick={handleDrawerClose}>
              <Close className='close-icon' />
            </IconButton>
          </DrawerHeader>
          <Divider />

          <List>
            {items.map((name, ix) => (
              <ListItemButton className='list-btn' key={ix} onClick={() => navigate(name)}>
                <ListItemIcon>{renderIcon(name)}</ListItemIcon>
                <ListItemText className='list-title' primary={startCase(lang[name as LangKeys])} />
              </ListItemButton>
            ))}
            <Divider style={{ borderColor: 'white' }} />
            <ListItemButton className='list-btn' onClick={logout}>
              <ListItemIcon>
                <Logout className='sidebar-icon' />
              </ListItemIcon>
              <ListItemText className='list-title' primary={lang.log_out} />
            </ListItemButton>
            <ListItem>
              <Fade in={open}>
                <Stack direction='row' alignItems='center' justifyContent='center' width='100%' padding={1} spacing={1}>
                  {!!pics?.length && <Avatar src={pics?.[0].url} />} <span>{email}</span>
                </Stack>
              </Fade>
            </ListItem>
          </List>
          <img src={sparkles} className='sparkles' />
        </Drawer>
      ) : null}

      <Box sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeader />
        <Routes />
      </Box>
    </Box>
  );
};

export { AppContent };
