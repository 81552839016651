import classNames from 'classnames';
import React, { FC } from 'react';
import './BaseContainer.scss';

interface BaseContainerProps {
  className?: string;
}

const BaseContainer: FC<BaseContainerProps> = ({ children, className }) => (
  <div className={classNames('base-container', className)}>{children}</div>
);

export { BaseContainer };
