import { addDoc, arrayUnion, deleteDoc, doc, FieldValue, getDoc, setDoc, where } from '@firebase/firestore';
import {
    AccountCircle,
    Apartment,
    Bathroom,
    Check,
    CropDin,
    CropFree,
    Delete,
    Edit,
    ElectricalServices,
    EventBusy,
    Home,
    Info,
    SensorDoor,
    Villa,
    Wc,
    Work,
} from '@mui/icons-material';
import { TextField, Button, Stack, Card, CardContent, Typography, Avatar, Grid, Box, CardActions } from '@mui/material';
import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmDialog } from '../../components/common/ConfirmDialog/ConfirmDialog';
import { deleteImagesFromStorage, FormModal, UploadedFile } from '../../components/common/FormModal/FormModal';
import { Currency, ProfilePage } from '../../components/common/ProfilePage/ProfilePage';
import { firestore } from '../../firebase';
import { languageAtom } from '../../state/controls';
import { unitFormItems } from '../ProjectProfile/formsConfig';
import { Project } from '../Projects/Projects';
import { Controller, useForm } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-mui';
import { useUserRole } from '../../hooks/useUserRole';
import './UnitProfile.scss';
import { collection, getDocs, query } from 'firebase/firestore';
import { CurrentOffer, Customer, Offer, offerFormItems } from '../Customers/Customers';
import { uniqBy } from 'lodash';
import { userDataAtom } from '../../state/auth';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
export interface Unit {
    id: string;
    projectId: string;
    unitSharedId: string;
    pics: UploadedFile[];
    floor: string;
    number: string;
    type: 'villa' | 'apartment';
    cost: string;
    size: string;
    plane: UploadedFile[];
    bathrooms: number;
    bedroom: number;
    facilities: string;
    status: string;
    buildArea: string;
    priceOffer: string;
    idType: 'Citizen' | 'Resident' | 'Facility';
    adStatus: 'Advertiser' | 'Authorized';
    idNumber: string;
    authNumber: string;
    adNumber: string;

}

export const deleteUnit = async ({ id, plane, projectId, pics }: Unit, parentProjectId?: string): Promise<void[]> => {
    let newParentUnits: string[] = [];

    if (parentProjectId) {
        const parentProject = (await (await getDoc(doc(firestore, 'projects', parentProjectId))).data()) as Project;
        newParentUnits = parentProject.units?.filter((unitId) => unitId !== id) as string[];
    }

    const promises = parentProjectId
        ? [
            deleteImagesFromStorage('units', id, 'plane', plane),
            deleteImagesFromStorage('units', id, 'pics', pics),
            setDoc(doc(firestore, 'projects', projectId), { units: newParentUnits }, { merge: true }),
            deleteDoc(doc(firestore, 'units', id)),
        ]
        : [
            deleteImagesFromStorage('units', id, 'plane', plane),
            deleteImagesFromStorage('units', id, 'pics', pics),
            deleteDoc(doc(firestore, 'units', id)),
        ];

    return Promise.all(promises);
};

const UnitProfile: FC = () => {
    const [userData] = useAtom(userDataAtom);
    const [{ lang, current }] = useAtom(languageAtom);
    const [unit, setUnit] = useState<Unit>();
    const [addUnitModalOpen, setAddUnitModalOpen] = useState(false);
    const [projectCurrency, setProjectCurrency] = useState<string>(Currency.SAR);
    const { replace } = useHistory();
    const { handleSubmit, control } = useForm();
    const { fbId, isAdmin, isDeveloper, isCustomer, isRealtor, phone } = useUserRole();
    const [customersArray, setCustomersArray] = useState<Customer[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [realtorOffers, setRealtorOffers] = useState<Offer[]>([]);
    const [currentOfferData, setCurrentOfferData] = useState<CurrentOffer>();
    const [offerModalOpen, setOfferModalOpen] = useState(false);
    const [parentProject, setParentProject] = useState<Project>();

    const handleDialogYes = async () => {
        setIsDeleting(true);

        const currentUnit = unit as Unit;

        await deleteUnit(currentUnit, currentUnit.projectId);

        setIsDeleting(false);
        setDialogOpen(false);

        toast.success('Project removed');

        replace(`/projects/${currentUnit.projectId}`);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getCustomers = useCallback(async () => {
        setIsFetching(true);
        const q = query(collection(firestore, 'customers'), where('units', 'array-contains', unit?.id));

        const customersInUnit: Customer[] = [];

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            customersInUnit.push({ ...doc.data(), id: doc.id } as Customer);
        });

        setCustomersArray(uniqBy(customersInUnit, 'id'));
        setIsFetching(false);
    }, [unit?.id]);

    const getRealtorOffers = useCallback(async () => {
        const offerQuery = query(collection(firestore, 'offers'), where('realtorId', '==', fbId));

        const offers = (await getDocs(offerQuery)).docs;

        setRealtorOffers(offers?.map((doc) => ({ ...(doc.data() as Offer), id: doc.id })) || []);
    }, [fbId]);

    useEffect(() => {
        if (unit) {
            getCustomers();
        }
    }, [getCustomers, unit]);

    useEffect(() => {
        if (isRealtor) {
            getRealtorOffers();
        }
    }, [getRealtorOffers, isRealtor]);

    const additionalValues = [
        {
            name: 'project',
            referenceValue: 'projectId',
            collectionName: 'projects',
        },
    ];

    const firstLine = {
        profileAsSource: false,
        label: lang.project,
        value: 'project.name',
        icon: <Work />,
        linkBase: '/projects',
        link: 'project.id',
    };

    const mainInfo = {
        label: lang.sale_price,
        value: 'priceOffer',
        additionalLabel: 'project.currency',
    };

    const otherInfo = {
        value: '',
        label: '',
        items: [
            {
                label: lang.address,
                profileAsSource: false,
                value: 'project.address',
                icon: <Home />,
            },
            {
                label: lang.floor,
                value: 'floor',
                icon: <Apartment />,
            },
            {
                label: lang.number,
                value: 'number',
                icon: <SensorDoor />,
            },
            {
                label: lang.property_type,
                value: 'type',
                icon: <Villa />,
            },
            {
                label: lang.size,
                value: 'size',
                additionalLabel: lang.sqm,
                icon: <CropFree />,
            },
            {
                label: lang.build_area,
                value: 'buildArea',
                additionalLabel: lang.sqm,
                icon: <CropDin />,
            },
            {
                label: lang.bathrooms,
                value: 'bathrooms',
                icon: <Bathroom />,
            },
            {
                label: lang.bedroom,
                value: 'bedroom',
                icon: <Wc />,
            },
            {
                label: lang.facilities,
                value: 'facilities',
                icon: <ElectricalServices />,
            },
            {
                label: lang.status,
                value: 'status',
                icon: <EventBusy />,
            },
            {
                label: lang.description,
                value: current === 'ar' ? 'description_ar' : 'description_en',
                icon: <Info />,
            },
        ],
    };

    const profileActions = [
        {
            title: lang.edit_profile,
            onClick: () => setAddUnitModalOpen(true),
            icon: <Edit color='info' />,
            shouldRender: isAdmin || isDeveloper,
        },
        {
            title: lang.delete_profile,
            onClick: () => setDialogOpen(true),
            icon: <Delete color='warning' />,
            shouldRender: isAdmin || isDeveloper,
        },
    ];

    const onSubmit = async (data: { textValue: string }) => {
        const currentUnit = unit as Unit;

        const userPhone = data.textValue || phone;

        const roleQuery = query(collection(firestore, 'roles'), where('phone', '==', userPhone));

        const [roleResult] = await (await getDocs(roleQuery)).docs;

        if (roleResult?.data() && roleResult?.data().role !== 'customer') {
            toast.error('The phone provided belongs to an existing user');
        } else {
            const q = query(collection(firestore, 'customers'), where('phone', '==', userPhone));

            const [customer] = await (await getDocs(q)).docs;

            if (customer?.exists()) {
                await setDoc(doc(firestore, 'customers', customer.id), { units: arrayUnion(currentUnit.id) }, { merge: true });
            } else {
                const newDoc = await addDoc(collection(firestore, 'customers'), {
                    units: [currentUnit.id],
                    phone: userPhone,
                });

                await addDoc(collection(firestore, 'roles'), {
                    phone: userPhone,
                    role: 'customer',
                    fbId: newDoc.id,
                });
            }
            toast.success('Unit added to customer wishlist');
        }
    };

    const onUnitSubmit = async (data: Record<string, FieldValue>) => {

        // first we do the Real Estate API 
        await setDoc(doc(firestore, 'units', `${unit?.id}`), data, { merge: true });

        toast.success('Unit profile updated');
        setAddUnitModalOpen(false);

        return Promise.resolve();
    };

    const renderCustomer = customersArray.map((customer, id) => {
        return (
            <Grid item key={id}>
                <Card sx={{ maxWidth: 300, marginBottom: 1 }} className='card'>
                    <CardContent>
                        <Stack direction='row' spacing={2}>
                            <Avatar className='icon'>
                                <AccountCircle className='account-icon' />
                            </Avatar>
                            <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                                {lang.phone} : {customer.phone}
                            </Typography>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        {realtorOffers?.find(({ unitId }) => unitId === unit?.id) ? (
                            <Stack
                                width='100%'
                                alignItems='center'
                                justifyContent='center'
                                direction='row'
                                spacing={1}
                                style={{ color: 'green' }}
                            >
                                <Check /> <span>{lang.offer_made}</span>
                            </Stack>
                        ) : (
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    setOfferModalOpen(true);
                                    setCurrentOfferData({
                                        customerId: customer.id,
                                        unitId: unit?.id || '',
                                        projectId: parentProject?.id || '',
                                        developerId: parentProject?.developerId || '',
                                        realtorId: fbId,
                                        currency: parentProject?.currency || '',
                                    });
                                }}
                                size='large'
                            >
                                {lang.make_offer}
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </Grid>
        );
    });

    const onOfferSubmit = async (data: Record<string, FieldValue>) => {
        const { customerId, ...offer } = { ...currentOfferData };

        const newOffer = await addDoc(collection(firestore, 'offers'), { ...offer, customerId, ...data });

        await setDoc(doc(firestore, 'customers', `${customerId}`), { offers: arrayUnion(newOffer.id) }, { merge: true });

        await setDoc(doc(firestore, 'realtors', fbId), { offers: arrayUnion(newOffer.id) }, { merge: true });

        await setDoc(doc(firestore, 'units', `${offer.unitId}`), { status: 'Booked' }, { merge: true });

        toast.success(lang.offer_sent);
        setOfferModalOpen(false);
        getCustomers();

        return Promise.resolve();
    };

    return (
        <div style={{ height: '100%' }}>
            {!isAdmin && !isDeveloper && !isRealtor && unit?.status === 'Available' && (
                <div className='phone-input'>
                    <form>
                        <Controller
                            control={control}
                            name={'textValue'}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Stack direction='row' spacing={2}>
                                        {!isCustomer && (
                                            <ReactPhoneInput defaultCountry='sa' component={TextField} value={value} onChange={onChange} />
                                        )}
                                        {!(userData as unknown as Customer)?.units?.find((u) => u === unit?.id) ? (
                                            <Button onClick={handleSubmit(onSubmit)}>{lang.interested}</Button>
                                        ) : (
                                            <Stack
                                                alignItems='center'
                                                justifyContent='center'
                                                direction='row'
                                                spacing={1}
                                                style={{ color: 'green' }}
                                            >
                                                <Check /> <span>{lang.interested}</span>
                                            </Stack>
                                        )}
                                    </Stack>
                                );
                            }}
                        />
                    </form>
                </div>
            )}
            <ProfilePage
                collectionName='units'
                additionalCollections={additionalValues}
                firstLine={firstLine}
                mainInfo={mainInfo}
                otherInfo={otherInfo}
                profileActions={profileActions}
                withMap
                getProfile={(profile, projectProfile) => {
                    setUnit(profile as Unit);
                    setParentProject(projectProfile as Project);
                }}
            />
            {customersArray != null && isRealtor && (
                <Box style={{ overflow: 'auto' }}>
                    <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                        {lang.customers_interested}
                    </Typography>
                    <Grid container spacing={2}>
                        {renderCustomer}
                    </Grid>
                </Box>
            )}

            <FormModal
                collectionName='units'
                title={lang.unit_form}
                formItems={unitFormItems(lang, parentProject?.currency)}
                isOpen={addUnitModalOpen}
                onClose={() => setAddUnitModalOpen(false)}
                onSubmit={onUnitSubmit}
                defaultFiles={{ pics: unit?.pics, plane: unit?.plane }}
                defaultValues={unit}
            />

            <FormModal
                collectionName='offers'
                title={lang.offer_form}
                formItems={offerFormItems(lang, parentProject?.currency)}
                isOpen={offerModalOpen}
                onClose={() => setOfferModalOpen(false)}
                onSubmit={onOfferSubmit}
            />

            <ConfirmDialog
                title={lang.confirm_delete_project}
                loading={isDeleting}
                loadingText={lang.working_delete}
                onClose={handleDialogClose}
                onYes={handleDialogYes}
                isOpen={dialogOpen}
            />
        </div>
    );
};

export { UnitProfile };
