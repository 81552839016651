import { Delete, Description, Edit, Phone, Web } from '@mui/icons-material';
import { collection, deleteDoc, doc, FieldValue, getDocs, query, setDoc, where } from '@firebase/firestore';
import { useAtom } from 'jotai';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfilePage } from '../../components/common/ProfilePage/ProfilePage';
import { firestore } from '../../firebase';
import { languageAtom } from '../../state/controls';
import { ConfirmDialog } from '../../components/common/ConfirmDialog/ConfirmDialog';
import { deleteImagesFromStorage, FormModal, UploadedFile } from '../../components/common/FormModal/FormModal';
import { realtorFormItems } from '../Realtors/Realtors';
import { useUserRole } from '../../hooks/useUserRole';

export interface Realtor {
  name: string;
  website: string;
  phone: string;
  description: string;
  photo: UploadedFile[];
  id: string;
  type: 'contractor' | 'freelance';
  uid: string;
}

const RealtorProfile: FC = () => {
  const [{ lang }] = useAtom(languageAtom);
  const { id } = useParams<{ id: string }>();
  const [realtor, setRealtor] = useState<Realtor>();
  const [addRealtorModalOpen, setAddRealtorModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { replace } = useHistory();
  const { isAdmin, isDeveloper } = useUserRole();

  const handleDialogYes = async () => {
    const { photo } = realtor as Realtor;

    setIsDeleting(true);

    await deleteImagesFromStorage('realtors', id, 'photo', photo);
    await deleteDoc(doc(firestore, 'realtors', id));

    const q = query(collection(firestore, 'roles'), where('fbId', '==', id));
    const [roleDoc] = (await getDocs(q)).docs;

    await deleteDoc(doc(firestore, 'roles', id));
    await deleteDoc(roleDoc.ref);

    setIsDeleting(false);
    setDialogOpen(false);

    toast.success('Realtor profile removed');

    replace(`/realtors`);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const mainInfo = {
    label: lang.description,
    value: 'description',
  };

  const otherInfo = {
    value: '',
    label: '',
    items: [
      {
        label: lang.type,
        value: 'type',
        icon: <Description />,
      },
      {
        label: lang.website,
        value: 'website',
        icon: <Web />,
      },
      {
        label: lang.phone,
        value: 'phone',
        icon: <Phone />,
      },
    ],
  };

  const profileActions = [
    {
      title: lang.edit_profile,
      onClick: () => setAddRealtorModalOpen(true),
      icon: <Edit color='info' />,
      shouldRender: isAdmin || isDeveloper,
    },
    {
      title: lang.delete_profile,
      onClick: () => setDialogOpen(true),
      icon: <Delete color='warning' />,
      shouldRender: isAdmin || isDeveloper,
    },
  ];

  const onSubmit = async (data: Record<string, FieldValue>) => {
    await setDoc(doc(firestore, 'realtors', `${realtor?.id}`), data, { merge: true });

    if (realtor?.uid) {
      await setDoc(
        doc(firestore, 'roles', realtor.uid),
        {
          phone: data.phone,
        },
        { merge: true }
      );
    }

    toast.success('Realtor profile info updated');

    return Promise.resolve();
  };

  return (
    <div style={{ height: '100%' }}>
      <ProfilePage
        collectionName='realtors'
        mainInfo={mainInfo}
        otherInfo={otherInfo}
        profileActions={profileActions}
        getProfile={(profile) => setRealtor(profile as Realtor)}
      />

      <FormModal
        collectionName='realtors'
        title={lang.realtor_form}
        formItems={realtorFormItems(lang)}
        isOpen={addRealtorModalOpen}
        onClose={() => setAddRealtorModalOpen(false)}
        onSubmit={onSubmit}
        defaultValues={realtor}
        defaultFiles={{ photo: realtor?.photo }}
      />

      <ConfirmDialog
        title={lang.confirm_delete_realtor}
        loading={isDeleting}
        loadingText={lang.working_delete}
        onClose={handleDialogClose}
        onYes={handleDialogYes}
        isOpen={dialogOpen}
      />
    </div>
  );
};

export { RealtorProfile };
