import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { PrivateRoute } from './PrivateRoute';
import { Projects } from './pages/Projects/Projects';
import { BaseContainer } from './components/common/BaseContainer/BaseContainer';

import { ProjectProfile } from './pages/ProjectProfile/ProjectProfile';
import { UnitProfile } from './pages/UnitProfile/UnitProfile';
import { Developers } from './pages/Developers/Developers';
import { DeveloperProfile } from './pages/DeveloperProfile/DeveloperProfile';
import { Realtors } from './pages/Realtors/Realtors';
import { RealtorProfile } from './pages/RealtorProfile/RealtorProfile';
import { Networks } from './pages/Networks/Networks';
import { NetworkProfile } from './pages/NetworkProfile/NetworkProfile';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Customers } from './pages/Customers/Customers';
import { Offers } from './pages/Offers/Offers';
import { Scans } from './pages/Scans/Scans';

const Routes: FC = () => {
  return (
    <BaseContainer>
      <PrivateRoute exact path='/projects' component={Projects} />
      <PrivateRoute exact path='/developers' component={Developers} />
      <PrivateRoute exact path='/realtors' component={Realtors} />
      <PrivateRoute exact path='/networks' component={Networks} />
      <PrivateRoute exact path='/dashboard' component={Dashboard} />
      <PrivateRoute exact path='/customers' component={Customers} />
      <PrivateRoute exact path='/offers' component={Offers} />
      <PrivateRoute exact path='/scans' component={Scans} />

      <Route exact path='/realtors/:id' component={RealtorProfile} />
      <Route exact path='/developers/:id' component={DeveloperProfile} />
      <Route exact path='/units/:id' component={UnitProfile} />
      <Route exact path='/projects/:id' component={ProjectProfile} />
      <Route exact path='/networks/:id' component={NetworkProfile} />
    </BaseContainer>
  );
};

export { Routes };
