import { PageLoader } from "../PageLoader/PageLoader";
import { CameraAlt, PictureAsPdf, QrCode } from "@mui/icons-material";
import { isString } from 'lodash';

import {
    TextField,
    Button,
    Grid,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Stack,
    Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { LangKeys, languageAtom } from "../../../state/controls";
import "./ProfilePage.scss";
import { GalleryModal } from "../GalleryModal/GalleryModal";
import { Link } from "react-router-dom";
import { Unsubscribe } from "@firebase/util";
import { useUserRole } from "../../../hooks/useUserRole";
import {
    collection,
    doc,
    FieldPath,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    where,
    WhereFilterOp,
} from "@firebase/firestore";
import { firestore } from "../../../firebase";
import { get, isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { UploadedFile } from "../FormModal/FormModal";
import { ShareQRModal } from "../../ShareQRModal/ShareQRModal";
import { ElementList } from "../ElementList/ElementList";

interface ProfileValue {
    label?: string;
    value: string | number;
    valueFormatter?: (value: any) => any;
    profileAsSource?: boolean;
    additionalLabel?: string;
    noDataMessage?: string;
    icon?: JSX.Element;
    linkBase?: string;
    link?: string;
    items?: ProfileValue[];
    action?: ProfileAction;
}

export type Constraint = [
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    value: string
];

interface AdditionalCollection {
    name: string;
    referenceValue: string;
    collectionName: string;
    queryConstraint?: Constraint;
}

interface ProfileAction {
    title: string;
    onClick: () => void;
    shouldRender?: boolean;
    icon?: JSX.Element;
}

interface ProfileProps {
    collectionName: string;
    additionalCollections?: AdditionalCollection[];
    firstLine?: ProfileValue;
    mainInfo?: ProfileValue;
    otherInfo?: ProfileValue;
    serviceInfo?: ProfileValue;
    videoInfo?: ProfileValue;
    listInfo?: ProfileValue;
    listMoreWarranties?: ProfileValue;
    profileActions?: ProfileAction[];
    cardsConfig?: ProfileValue;
    withMap?: boolean;
    getProfile?: (profile: unknown, additionalData: unknown) => void;
}

export const Currency: Record<string, string> = {
    SAR: "﷼‎",
    USD: "$",
    EUR: "€",
};

const ProfilePage: FC<ProfileProps> = ({
    firstLine,
    collectionName,
    additionalCollections,
    profileActions,
    mainInfo,
    serviceInfo,
    otherInfo,
    listInfo,
    listMoreWarranties,
    cardsConfig,
    withMap,
    getProfile,
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [{ lang }] = useAtom(languageAtom);
    const [galleryOpen, setGalleryOpen] = useState(false);


    const [showQR, setShowQR] = useState(false);
    const [profile, setProfile] = useState<Record<string, unknown>>();
    const [additionalData, setAdditionalData] =
        useState<Record<string, unknown>>();
    const unsubscribe = useRef<Unsubscribe>();
    const { id } = useParams<{ id: string }>();
    const { isAdmin, isDeveloper } = useUserRole();

    const pics = [
        ...(get(profile, "pics", []) as UploadedFile[]),
        ...(get(profile, "plane", []) as UploadedFile[]),
    ];

    useEffect(() => {
        if (!profile) {
            setIsFetching(true);
            unsubscribe.current = onSnapshot(
                doc(firestore, collectionName, id),
                async (profileDoc) => {
                    const currentProfile = { ...profileDoc.data(), id: profileDoc.id };
                    let newAdditionalData: Record<string, unknown> = {};

                    if (additionalCollections?.length) {
                        await Promise.all(
                            additionalCollections.map(
                                async ({
                                    name,
                                    referenceValue,
                                    collectionName,
                                    queryConstraint,
                                }) => {
                                    const newDataReference = get(currentProfile, referenceValue);

                                    if (
                                        isArray(newDataReference) &&
                                        !isEmpty(newDataReference) &&
                                        queryConstraint
                                    ) {
                                        const [fieldPath, opStr, value] = queryConstraint;
                                        const constraint = where(
                                            fieldPath,
                                            opStr,
                                            get(currentProfile, value)
                                        );
                                        const q = query(
                                            collection(firestore, collectionName),
                                            constraint
                                        );
                                        const querySnapshot = await getDocs(q);
                                        const docs: Record<string, unknown>[] = [];

                                        querySnapshot.forEach((extraDoc) => {
                                            docs.push({ ...extraDoc.data(), id: extraDoc.id });
                                        });

                                        newAdditionalData = { ...newAdditionalData, [name]: docs };
                                    } else if (!isEmpty(newDataReference)) {
                                        const newData = await getDoc(
                                            doc(firestore, collectionName, newDataReference)
                                        );

                                        newAdditionalData = {
                                            ...newAdditionalData,
                                            [name]: { ...newData.data(), id: newData.id },
                                        };
                                    }

                                    return Promise.resolve();
                                }
                            )
                        );
                    }

                    const profileData = {
                        ...currentProfile,
                        id: profileDoc.id,
                    } as Record<string, unknown>;

                    setProfile(profileData);
                    setAdditionalData(newAdditionalData);
                    setIsFetching(false);
                    getProfile?.(profileData as unknown, newAdditionalData);
                }
            );
        }
    }, [
        additionalCollections,
        additionalData,
        collectionName,
        getProfile,
        id,
        profile,
    ]);

    useEffect(() => {
        return () => {
            unsubscribe.current?.();
        };
    }, []);

    const renderHeading = () => {
        const name = get(profile, "name") as string;
        const logo = (get(profile, "logo") ||
            get(profile, "photo")) as UploadedFile[];
        const firstImage = !!pics?.length && pics[0];

        return (
            <div className="heading-image">
                <div className="info">
                    <div className="info-content">
                        {!!logo?.length && (
                            <div className="logo">
                                <img src={logo[0].url} />
                            </div>
                        )}
                        <div className="info-text">
                            <div className="name">{name}</div>
                            {!!pics?.length && (
                                <div className="image-count">
                                    <Button
                                        className="photos-btn"
                                        variant="outlined"
                                        endIcon={<CameraAlt sx={{ color: "white" }} />}
                                        onClick={() => {
                                            setGalleryOpen(true);
                                        }}
                                    >
                                        <Stack direction="row" spacing={2}>
                                            {pics?.length} {lang.photos}
                                        </Stack>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {firstImage && (
                    <div className="top-image">
                        <img src={firstImage.url} />
                    </div>
                )}
            </div>
        );
    };

    const renderFirstLines = () => {
        const {
            icon,
            label,
            link,
            linkBase,
            value,
            profileAsSource = true,
        } = firstLine as ProfileValue;
        const source = profileAsSource ? profile : additionalData;

        return (
            <div className="first-line">
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    {icon}
                    <span>{label}:</span>
                    <span>
                        {link ? (
                            <Link
                                style={{ marginLeft: 0 }}
                                to={`${linkBase}/${get(source, link)}`}
                            >
                                {get(source, value)}
                            </Link>
                        ) : (
                            value
                        )}
                    </span>
                </Stack>
            </div>
        );
    };

    const renderMainInfo = () => {
        // here project name
        const { label, additionalLabel, value, items } = mainInfo as ProfileValue;

        const source = profile as Record<string, any>;

        const renderAdditional = () => {
            const value = (get(additionalData, additionalLabel as string) ||
                get(profile, additionalLabel as string)) as string;
            return Currency[value] || `(${value})`;
        };

        return (
            <div>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    divider={<>&nbsp;</>}
                >
                    <Stack direction="row" spacing={1}>
                        <Typography variant="overline" color="text.secondary">
                            {label}
                        </Typography>
                        <Typography variant="h5">
                            {get(profile, value)}
                            &nbsp;
                            {additionalLabel && renderAdditional()}
                        </Typography>
                    </Stack>
                </Stack>
                {items?.length && (
                    <Typography variant="subtitle2" color="GrayText">
                        {items
                            .map(
                                ({ value, label, profileAsSource = true }) =>
                                    `${label} ${get(
                                        profileAsSource ? profile : additionalData,
                                        value
                                    )}`
                            )
                            .join(" · ")}
                        {source?.units
                            ? ` · ${lang.units} ${source.units.length}`
                            : ` · ${lang.units} ${0}`}
                    </Typography>
                )}
            </div>
        );
    };

    const renderVideoInfo = () => {
        // here project name
        const { label, additionalLabel, value, items } = mainInfo as ProfileValue;

        const source = profile as Record<string, any>;

        const renderAdditional = () => {
            const value = (get(additionalData, additionalLabel as string) ||
                get(profile, additionalLabel as string)) as string;
            return Currency[value] || `(${value})`;
        };

        return (
            <div>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    divider={<>&nbsp;</>}
                >
                    <Stack direction="row" spacing={1}>
                        <Typography variant="h6">{lang.video}</Typography>
                    </Stack>
                </Stack>
                {items?.length &&
                    items.map(
                        (
                            {
                                icon,
                                value,
                                label,
                                valueFormatter,
                                additionalLabel,
                                profileAsSource = true,
                            },
                            ix
                        ) => {
                            const source = profileAsSource ? profile : additionalData;
                            const displayValue = get(source, value);
                            return (
                                <>
                                    <Grid container>
                                        <Grid xs={6} item color="darkgray" className="fragment_key">
                                            {icon}
                                            &nbsp;
                                            {label}
                                        </Grid>
                                        <Grid xs={6} item>
                                            {valueFormatter && displayValue
                                                ? valueFormatter(displayValue)
                                                : lang[displayValue as LangKeys] || displayValue}
                                            &nbsp;
                                            {additionalLabel &&
                                                `(${get(source, additionalLabel, additionalLabel)})`}
                                            {lang.year + " " + lang.warranty}
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }
                    )}
            </div>
        );
    };
    const renderListInfo = () => {
        const { items } = listInfo as ProfileValue;
        return (
            <>
                <Typography variant="h6" gutterBottom component="div">
                    {lang.warranties}:
                </Typography>
                {items?.length &&
                    items.map(
                        (
                            {
                                icon,
                                value,
                                label,
                                valueFormatter,
                                additionalLabel,
                                profileAsSource = true,
                            },
                            ix
                        ) => {
                            const source = profileAsSource ? profile : additionalData;
                            const displayValue = get(source, value);
                            return (
                                <>
                                    <Grid container>
                                        <Grid xs={6} item color="darkgray" className="fragment_key">
                                            {icon}
                                            &nbsp;
                                            {label}
                                        </Grid>
                                        <Grid xs={6} item>
                                            {valueFormatter && displayValue
                                                ? valueFormatter(displayValue)
                                                : lang[displayValue as LangKeys] || displayValue}
                                            &nbsp;
                                            {additionalLabel &&
                                                `(${get(source, additionalLabel, additionalLabel)})`}
                                            {lang.year + " " + lang.warranty}
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }
                    )}
            </>
        );
    };
    /*  const renderListMoreWarrantiesInfo = () => {
      const { items } = listMoreWarranties as ProfileValue;
      return (
        <>
          <Grid container>
            {items?.length &&
              items.map(
                (
                  {
                    icon,
                    value,
                    label,
                    valueFormatter,
                    additionalLabel,
                    profileAsSource = true,
                  },
                  ix
                ) => {
                  const source = profileAsSource ? profile : additionalData;
                  const displayValue = get(source, value);
  
                  return (
                    <>
                      <Grid xs={6} item color={icon ? "darkgray" : ""}>
                        {icon}
                        {valueFormatter && displayValue
                          ? valueFormatter(displayValue)
                          : lang[displayValue as LangKeys] || displayValue}
                        &nbsp;
                        {additionalLabel &&
                          `(${get(source, additionalLabel, additionalLabel)})`}
                        {!!icon ? "" : lang.year + " " + lang.warranty}
                      </Grid>
                    </>
                  );
                }
              )}
          </Grid>
        </>
      );
    };
  */
    const renderOtherInfo = () => {
        const { items } = otherInfo as ProfileValue;
        return (
            <Grid container spacing={1}>
                {items?.length &&
                    items.map(
                        (
                            {
                                icon,
                                value,
                                label,
                                valueFormatter,
                                additionalLabel,
                                profileAsSource = true,
                            },
                            ix
                        ) => {
                            const source = profileAsSource ? profile : additionalData;
                            const displayValue = get(source, value);

                            return (
                                <Fragment key={ix}>
                                    <Grid xs={6} item color="darkgray" className="fragment_key">
                                        <Stack direction="row" alignItems="center">
                                            {icon}
                                            &nbsp;
                                            {label}
                                        </Stack>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Stack direction="row" alignItems="center">
                                            {valueFormatter && displayValue
                                                ? valueFormatter(displayValue)
                                                : lang[displayValue as LangKeys] || displayValue}
                                            &nbsp;
                                            {additionalLabel &&
                                                `(${get(source, additionalLabel, additionalLabel)})`}
                                        </Stack>
                                    </Grid>
                                </Fragment>
                            );
                        }
                    )}
            </Grid>
        );
    };
    const renderServiceInfo = () => {
        const { items } = serviceInfo as ProfileValue;
        return (
            <>
                <Typography variant="h6" gutterBottom component="div">
                    {lang.available_services_nearby}:
                </Typography>
                {items?.length &&
                    items.map(
                        (
                            {
                                icon,
                                value,
                                label,
                                valueFormatter,
                                additionalLabel,
                                profileAsSource = true,
                            },
                            ix
                        ) => {
                            const source = profileAsSource ? profile : additionalData;
                            const displayValue = get(source, value);
                            return (
                                <>
                                    {
                                        displayValue &&
                                        <Grid container>
                                            <Grid xs={6} item color="darkgray" className="fragment_key">
                                                {icon}
                                                &nbsp;
                                                {label}
                                            </Grid>
                                            <Grid xs={6} item>
                                                {valueFormatter && displayValue
                                                    ? valueFormatter(displayValue)
                                                    : lang[displayValue as LangKeys] || displayValue}
                                                &nbsp;
                                                {additionalLabel &&
                                                    `(${get(source, additionalLabel, additionalLabel)})`}
                                            </Grid>
                                        </Grid>
                                    }
                                </>
                            );
                        }
                    )}
            </>
        );
    };
    const renderUnuints = () => {
        const {
            profileAsSource = true,
            value,
            label,
            items,
            link,
            action,
            noDataMessage,
        } = cardsConfig as ProfileValue;
        const source = profileAsSource ? profile : additionalData;

        const cards = get(source, value) as Record<string, unknown>[];

        return (
            <div>
                <Stack spacing={1}>
                    {(isAdmin || isDeveloper) && action && (
                        <Stack sx={{ p: 2 }} justifyContent="center">
                            <Button onClick={action.onClick}>{action.title}</Button>
                        </Stack>
                    )}
                    <Typography variant="h5">{label}</Typography>
                    {cards?.length ? (
                        <div>
                            {cards?.map((card, ix) => (
                                <ElementList
                                    className="card"
                                    key={ix}
                                    element={{
                                        linkPath: link || "",
                                        id: get(card, "id") as string,
                                        name: get(card, "name") as string,
                                        pics: get(card, "pics") as UploadedFile[],
                                        content:
                                            items?.map(({ icon, value, label, additionalLabel }) => {
                                                let displayValue = (lang[card[value] as LangKeys] ||
                                                    card[value]) as string;

                                                if (isArray(displayValue)) {
                                                    displayValue = String(displayValue.length);
                                                }

                                                return {
                                                    icon,
                                                    value: additionalLabel
                                                        ? `${displayValue} (${additionalLabel})`
                                                        : displayValue,
                                                    label,
                                                };
                                            }) || [],
                                    }}
                                />
                            ))}
                        </div>
                    ) : (
                        <Stack sx={{ p: 2 }} justifyContent="center">
                            {noDataMessage || lang.no_data}
                        </Stack>
                    )}
                </Stack>
            </div>
        );

    }
    const [searchTerm, setSearchTerm] = useState('');


    const onChangeHandler = (e: { target: { value: any; }; }) => {
        // set the value to the search term
        setSearchTerm(`${e.target.value}`)


    };

    const renderCards = () => {
        const {
            profileAsSource = true,
            value,
            label,
            items,
            link,
            action,
            noDataMessage,
        } = cardsConfig as ProfileValue;


        const source = profileAsSource ? profile : additionalData;

        const cards = get(source, value) as Record<string, unknown>[];



        return (
            <div>
                <Stack spacing={1}>
                    {(isAdmin || isDeveloper) && action && (
                        <Stack sx={{ p: 2 }} justifyContent="center">
                            <Button onClick={action.onClick}>{action.title}</Button>

                            <TextField
                                label={lang.search}
                                variant='standard'
                                value={searchTerm}
                                onChange={onChangeHandler}
                                type='number'
                            />

                        </Stack>


                    )}

                    <Typography variant="h5">{label}</Typography>

                    {
                        cards?.length ? (
                            <div>
                                {searchTerm === '' ?
                                    cards?.map((card, ix) => (
                                        <ElementList
                                            className="card"
                                            key={ix}
                                            element={
                                                {
                                                    linkPath: link || "",
                                                    id: get(card, "id") as string,
                                                    name: get(card, "name") as string,
                                                    pics: get(card, "pics") as UploadedFile[],
                                                    content:
                                                        items?.map(({ icon, value, label, additionalLabel }) => {
                                                            let displayValue = (lang[card[value] as LangKeys] ||
                                                                card[value]) as string;

                                                            if (isArray(displayValue)) {
                                                                displayValue = String(displayValue.length);
                                                            }

                                                            return {
                                                                icon,
                                                                value: additionalLabel
                                                                    ? `${displayValue} (${additionalLabel})`
                                                                    : displayValue,
                                                                label,
                                                            };
                                                        }) || [],
                                                }}
                                        />
                                    ))
                                    :
                                    cards?.filter(obj => obj.number === searchTerm).map((card, ix) => (
                                        <ElementList
                                            className="card"
                                            key={ix}
                                            element={
                                                {
                                                    linkPath: link || "",
                                                    id: get(card, "id") as string,
                                                    name: get(card, "name") as string,
                                                    pics: get(card, "pics") as UploadedFile[],
                                                    content:
                                                        items?.map(({ icon, value, label, additionalLabel }) => {
                                                            let displayValue = (lang[card[value] as LangKeys] ||
                                                                card[value]) as string;

                                                            if (isArray(displayValue)) {
                                                                displayValue = String(displayValue.length);
                                                            }

                                                            return {
                                                                icon,
                                                                value: additionalLabel
                                                                    ? `${displayValue} (${additionalLabel})`
                                                                    : displayValue,
                                                                label,
                                                            };
                                                        }) || [],
                                                }}
                                        />
                                    ))


                                }

                            </div>
                        ) : (
                            <Stack sx={{ p: 2 }} justifyContent="center">
                                {noDataMessage || lang.no_data}
                            </Stack>
                        )}
                </Stack>
            </div>
        );
    };


    const renderSpeedDialActions = () => {
        const actions = [
            <SpeedDialAction
                key="qr"
                icon={<QrCode color="action" />}
                tooltipTitle={lang.share}
                onClick={() => setShowQR(true)}
            />,
            <SpeedDialAction
                key="pdf"
                icon={<PictureAsPdf color="error" />}
                tooltipTitle={lang.print}
                onClick={() => toast.info("TODO: Redirect to generate PDF Page")}
            />,
        ];

        if (profileActions?.length) {
            profileActions.forEach(({ title, icon, onClick, shouldRender }, ix) => {
                if (shouldRender) {
                    actions.push(
                        <SpeedDialAction
                            key={ix}
                            icon={icon}
                            tooltipTitle={title}
                            onClick={onClick}
                        />
                    );
                }
            });
        }

        return actions;
    };

    const RenderMap = () => {
        const location = get(profile, "location") as string;
        const defaultProps = {
            center: {
                lat: parseInt(location?.split(",")[0] || "0"),
                lng: parseInt(location?.split(",")[1] || "0"),
            },
            zoom: 11,
        };

        const MapWithAMarker = withScriptjs(
            withGoogleMap((props) => (
                <GoogleMap
                    defaultZoom={defaultProps.zoom}
                    defaultCenter={defaultProps.center}
                >
                    <Marker position={defaultProps.center} />
                </GoogleMap>
            ))
        );

        return (
            <div className="map-wrapper">
                <MapWithAMarker
                    //TODO: Change key when billing is enabled
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBhe5wsjo0JJoa5dedVm2MdoXt5jIQjWFw&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `200px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                ></MapWithAMarker>
            </div>
        );
    };

    return (
        <div className="profile">
            <div>
                <Grid container spacing={2}>
                    {!isFetching ? (
                        <>
                            <Grid className="top-section" item xs={12} md={6}>
                                {renderHeading()}
                                <iframe width="420" height="315"
                                    src={get(profile, 'video') as any}>
                                </iframe>
                                {withMap && <RenderMap />}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1} direction="row" alignItems="center">
                                    <Grid item xs={12} className="description">
                                        {mainInfo && renderMainInfo()}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    className="container-spacing"
                                >
                                    {/* <Grid item xs={12} className="description">
                    {videoInfo && renderVideoInfo()}
                  </Grid> */}
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    className="container-spacing"
                                >
                                    <Grid item xs={12} className="description">
                                        {otherInfo && renderOtherInfo()}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    className="container-spacing"
                                >
                                    <Grid item xs={12} className="description">
                                        {serviceInfo && renderServiceInfo()}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    className="container-spacing"
                                >
                                    <Grid item xs={12} className="description">
                                        {listInfo && renderListInfo()}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    className="container-spacing"
                                >
                                    <Grid item xs={12} className="description"></Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    className="container-spacing"
                                >
                                    <Grid item xs={12} className="description">
                                        {firstLine && renderFirstLines()}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className="profile-body">
                                {!!pics?.length && (
                                    <GalleryModal
                                        isOpen={galleryOpen}
                                        onClose={() => setGalleryOpen(false)}
                                        images={pics}
                                    />
                                )}
                            </div>

                            <SpeedDial
                                className="actions"
                                ariaLabel="speed dial"
                                icon={<SpeedDialIcon />}
                            >
                                {renderSpeedDialActions()}
                            </SpeedDial>

                            <ShareQRModal isOpen={showQR} onClose={() => setShowQR(false)} />
                        </>
                    ) : (
                        <PageLoader />
                    )}
                </Grid>
                {!isFetching && cardsConfig && renderCards()}

            </div>
        </div>
    );
};

export { ProfilePage };
