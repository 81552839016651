const lang = {
  en: {
    lang: "العربية",
    email: "Email",
    password: "Password",
    log_in: "Log In",
    forgot_password: "Forgot password?",
    profile: "Profile",
    projects: "Projects",
    realtors: "Realtors",
    developers: "Developers",
    networks: "Networks",
    log_out: "Log Out",
    public_profile: "Public Profile",
    empty_user_data: "Empty User Data",
    uid: "Unique Id",
    name: "Name",
    website: "Website",
    contact: "Contact",
    description: "Description",
    role: "Role",
    new_project: "New Project",
    search: "Search",
    edit_project: "Edit Project",
    delete_project: "Delete Project",
    photos: "Photos",
    cost: "Cost",
    Citizen: "Citizen",
    Resident: "Resident",
    facility: "Facility",
    address: "Address",
    reservation: "Reservation",
    start_finish_date: "Start and Finish date",
    available_services_nearby: "Available Services Nearby",
    warranties: "Warranties",
    confirm_delete_project: "Are you sure you want to remove this project?",
    confirm_delete_unit: "Are you sure you want to remove this unit?",
    confirm_delete_developer: "Are you sure you want to remove this developer?",
    confirm_delete_realtor: "Are you sure you want to remove this realtor?",
    confirm_delete_network: "Are you sure you want to remove this network?",
    no: "No",
    yes: "Yes",
    city: "City",
    project_form: "Project Form",
    save: "Save",
    reset: "Reset",
    project_name: "Project Name",
    latitude: "Latitude",
    longitude: "Longitude",
    max_reservation: "Max. Reservation Time Period",
    project_logo: "Project Logo",
    project_images: "Project Images",
    unit_plane: "Unit Plan",
    unit_images: "Unit Images",
    from: "From",
    end: "End",
    deliveryDate: "Delivery Date",
    to: "To",
    facilities: "Facilities",
    developer: "Developer",
    admin: "Admin",
    clear: "Clear",
    drag_n_drop: "Drag n drop an image here, or click to select one",
    create: "Create",
    units: "Project Units",
    add_unit: "Add a unit",
    unit_form: "Unit Form",
    floor: "No. of Floors",
    property_type: "Property Type",
    currency: "Currency",
    number: "Number",
    apartment: "Apartment",
    villa: "Villa",
    size: "Size",
    floors: "Floors",
    bathrooms: "Bathrooms",
    bedroom: "Bedroom",
    status: "Status",
    available: "Available",
    booked: "Booked",
    sold: "Sold",
    price_offer: "Price Offer",
    build_area: "Build Area",
    sqm: "sq. m.",
    belongs_to_project: "Belongs to Project",
    no_images: "No Images",
    new_developer: "New Developer",
    developer_form: "Developer Form",
    no_dev_projects: "This Developer doesn't have any projects yet",
    no_data: "No Data",
    logo: "Logo",
    working_delete:
      "Working on related updates, please do not leave this page.",
    no_developers: "No Developers to show",
    share: "Share",
    print: "Print",
    edit_profile: "Edit Profile",
    delete_profile: "Delete Profile",
    phone: "Phone Number",
    idType: "ID Type",
    Authorized: "Authorized",
    Advertiser: "Advertiser",
    adStatus: "Advertiser Status",
    idNumber: "ID Number",
    authNumber: "Authorization Number",
    adNumber: "Advertiser Number",
    login_credentials: "Credentials",
    code_sent: "A verification code was sent to the provided phone number",
    code: "SMS Code",
    user_not_found: "User not found",
    wrong_password: "Incorrect password",
    invalid_code: "Invalid code",
    new_realtor: "New Realtor",
    realtor_form: "Realtor Form",
    no_realtors: "No Realtors to show",
    photo: "Photo",
    expired_code: "Code expired, refresh and try again",
    invalid_phone: "Invalid Phone Number",
    phone_taken: "A user is already registered to that phone number",
    register_title: "Sign up and activate your profile",
    inactive_user: "Activating profile for:",
    register: "Register",
    gender: "Gender",
    age: "Age",
    male: "Male",
    female: "Female",
    project: "Project",
    days: "days",
    type: "Type",
    AdvertiserStatus: "Advertiser Status",

    contractor: "Contractor",
    freelancer: "Freelancer",
    new_network: "New Network",
    network_form: "Network Form",
    edit_drag_n_drop: "Add More Pictures",
    dashboard: "Dashboard",
    welcome: "Welcome!",
    user_deactivated: "This User has been desactivated, contact support",
    interested: "Interested",
    date: "Date",
    description_en: "Description in English",
    description_ar: "Description in Arabic",
    warranties_en: "Warranties in English",
    warranties_ar: "Warranties in Arabic",
    facilities_en: "Facilities in English",
    facilities_ar: "Facilities in Arabic",
    available_services_nearby_en: "Available Services Nearby in English",
    available_services_nearby_ar: "Available Services Nearby in Arabic",
    city_en: "City in English",
    city_ar: "City in Arabic",
    customers_interested: "Customers Interested",
    make_offer: "Make an offer",
    create_multi: "Create Multiple",
    no_customers: "No Customers Interested in units yet",
    offer_form: "Offer Form",
    offer_sent: "Offer sent to customer",
    offer_made: "Offer made",
    no_offers: "No offers made yet",
    offers: "Offers",
    unit: "Unit",
    warranty: "Warranty",
    scans: "Scans",
    other_units: "Other units in your related projects",
    no_interested_units: "No interested units yet",
    sale_price: "Sale Price",
    electricity: "Electrical Wiring Efficiency",
    concrete: "Structural structure and concrete",
    conditioning: "Conditioning Extension",
    reservoirs: "Water Reservoirs",
    paints: "Paints",
    inoutdoor: "Indoor and Outdoor Doors",
    sanitary: "Sanitary Ware",
    isolation: "Isolation works",
    plumping: "Plumbing Work",
    floors_x: "Tiles and Floors",
    year: "Year",
    more: "More",
    brochure: "brochure",
    video: "Video",
    electricity_cables: "Electricity",
    street: "street",
    infrastructure: "Infrastructure",
    street_lighting: "Street Lighting",
    solar_energy: "Solar Energy",
    disable: "Disable",
    enable: "Enable",
  },
  arab: {
    lang: "Eng",
    email: "البريد الالكتروني",
    password: "كلمة المرور",
    log_in: "تسجيل الدخول",
    forgot_password: "هل نسيت كلمة المرور؟",
    profile: "الملف التعريفي",
    projects: "المشاريع",
    realtors: "السماسرة",
    developers: "المطورين",
    networks: "شبكات الاتصال",
    log_out: "تسجيل الخروج",
    public_profile: "الملف التعريفي العام",
    empty_user_data: "بيانات هذا المستخدم غير موجودة",
    uid: "اسم مستخدم مميز",
    name: "الاسم",
    website: "الموقع الالكتروني",
    contact: "جهة الاتصال",
    description: "الوصف",
    role: "الدور",
    new_project: "مشروع جديد",
    search: "بحث",
    edit_project: "تعديل المشروع",
    delete_project: "حذف المشروع",
    AdvertiserStatus: "حالة المعلن",
    photos: "الصور",
    idType: "نوع الهوية",
    Advertiser: "معلن",
    Authorized: "مفوض",
    adStatus: "حالة المعلن",
    idNumber: "رقم الهوية",
    authNumber: "رقم التفويض",
    adNumber: "رقم الاعلان",
    Citizen: "مواطن",
    Resident: "مقيم",
    facility: "منشآه",
    cost: "التكلفة",
    address: "العنوان",
    reservation: "الحجز",
    start_finish_date: "تاريخ البدء والانتهاء",
    available_services_nearby: "الخدمات المتاحة بالقرب منك",
    warranties: "الضمانات",
    confirm_delete_project: "هل أنت متأكد من رغبتك بحذف هذا المشروع؟",
    confirm_delete_unit: "هل أنت متأكد من رغبتك بحذف هذه الوحدة السكنية؟",
    confirm_delete_developer: "هل أنت متأكد من رغبتك بحذف هذا المطور؟",
    confirm_delete_realtor: "هل أنت متأكد من رغبتك بحذف هذا السمسار؟",
    confirm_delete_network: "هل أنت متأكد من رغبتك بحذف شبكة الاتصال هذه؟",
    no: "لا",
    yes: "نعم",
    city: "المدينة",
    project_form: "نموذج المشروع",
    save: "حفظ",
    reset: "إعادة ضبط",
    project_name: "اسم المشروع",
    latitude: "خط الطول",
    longitude: "خط العرض",
    max_reservation: "أقصى موعد للحجز",
    project_logo: "شعار المشروع",
    project_images: "صورة المشروع",
    from: "من:",
    deliveryDate: "موعد التسليم",
    end: "نهاية",
    to: "إلى:",
    facilities: "المرافق",
    developer: "المطور",
    admin: "Admin",
    clear: "مسح",
    drag_n_drop: "السحب والإفلات",
    create: "إنشاء",
    units: "الوحدات السكنية",
    add_unit: "أضف الوحدة السكنية",
    unit_form: "نموذج الوحدة السكنية",
    floor: "عدد الطوابق",
    property_type: "نوع الملكية",
    unit_plane: "مخطط الوحدة",
    unit_images: "صور الوحدة السكنية",
    currency: "العملة",
    number: "الرقم",
    apartment: "الشقة",
    villa: "الفيلا",
    size: "مساحة الارض",
    floors: "الطوابق",
    bathrooms: "الحمامات",
    bedroom: "Bedroom",
    status: "الحالة",
    available: "متاح",
    booked: "تم حجزه",
    sold: "تم بيعه",
    price_offer: "عرض السعر",
    build_area: "مسطح البناء",
    sqm: "متر مربع",
    belongs_to_project: "ملحقات المشروع",
    no_images: "لا يوجد صور",
    new_developer: "مطور جديد",
    developer_form: "نموذج المطور",
    no_dev_projects: "هذا المطور لم يمتلك أي مشايع بعد",
    no_data: "لا يوجد بيانات",
    logo: "الشعار",
    working_delete: "نقوم بالعمل على تحديثات ذات صلة، الرجاء عدم مغادرة الصفحة",
    no_developers: "لا يوجد مطورين",
    share: "مشاركة",
    print: "طباعة",
    edit_profile: "تعديل الملف التعريفي",
    delete_profile: "حذف الملف التعريفي",
    phone: "رقم الجوال",
    login_credentials: "بيانات اعتماد تسجيل الدخول",
    code_sent: "تم إرسال رمز التأكيد إلى الرقم الذي تم التسجيل به",
    code: "تم إرسال الرمز عبر رسالة نصية",
    user_not_found: "المستخدم غير موجود",
    wrong_password: "كلمة المرور خاطئة",
    invalid_code: "الرمز غير صالح",
    new_realtor: "سمسار جديد",
    realtor_form: "نموذج السمسار",
    no_realtors: "لا يوجد سماسرة",
    photo: "الصورة",
    expired_code: "رمز التأكيد منتهي الصلاحية، حدث الصفحة وحاول مرة أخرى",
    invalid_phone: "رقم الجوال غير صالح",
    phone_taken: "هناك حساب آخر مسجل على رقم الجوال المسجل",
    register_title: "أنشئ حساب وقم بتفعيله",
    inactive_user: "تفعيل الحساب من أجل",
    register: "قم بالتسجيل",
    gender: "الجنس",
    age: "العمر",
    male: "ذكر",
    female: "أنثى",
    project: "المشروع",
    days: "الأيام",
    type: "النوع",
    contractor: "المتعاقد",
    freelancer: "المستقل",
    new_network: "شبكة اتصال جديدة",
    network_form: "نموذج شبكة الاتصال",
    edit_drag_n_drop: "أضف المزيد من الصور",
    dashboard: "لوحة التحكم",
    welcome: "مرحبا",
    user_deactivated: "تم منع الوصول لهذا المستخدم ، برجاء التواصل مع الدعم",
    interested: "مهتم",
    date: "تاريخ",
    description_en: "الوصف بالانجليزية",
    description_ar: "الوصف بالعربي",
    warranties_en: "الضمانات بالانجليزية",
    warranties_ar: "الضمانات بالعربي",
    facilities_en: "الخدمات بالانجليزية",
    facilities_ar: "الخدمات بالعربي",
    city_en: "المدينة بالانجليزية",
    city_ar: "المدينة بالعربي",
    available_services_nearby_en: "الخدمات المتاحة بالانجليزية",
    available_services_nearby_ar: "الخدمات المتاحة بالعربي",
    customers_interested: "العملاء مهتمون",
    make_offer: "تقديم عرض ",
    create_multi: "Create Multiple",
    no_customers: "No Customers Interested in units yet",
    offer_form: "Offer Form",
    offer_sent: "Offer sent to customer",
    offer_made: "Offer made",
    no_offers: "No offers made yet",
    offers: "Offers",
    unit: "Unit",
    scans: "Scans",
    other_units: "Other units in your related projects",
    no_interested_units: "No interested units yet",
    sale_price: "Sale Price",
    warranty: "ضمان",
    electricity: "التمديدات الكهربائية ",
    concrete: "الهيكل الانشائي والخرسانة ",
    conditioning: "تمديد التكييف",
    reservoirs: "خزانات المياه",
    paints: "الدهانات",
    inoutdoor: "الأبواب الداخلية والخارجية",
    sanitary: "الأدوات الصحية",
    isolation: "أعمال العزل",
    plumping: "اعمال السباكة",
    floors_x: "البلاط والارضيات",
    year: "سنة",
    more: "أخرى",
    brochure: "البروشور",
    video: "فيديو المشروع",
    electricity_cables: "الكهرباء",
    street: "الطرق",
    infrastructure: "البنية التحتية",
    street_lighting: "إنارة الشوارع",
    solar_energy: "طاقة شمسية",
    disable: "اخفاء",
    enable: "تفعيل",
  },
};

export { lang };
