import { AccountCircle, Check, ImageNotSupported } from '@mui/icons-material';
import { Button, Stack, Card, CardContent, Typography, Avatar, Box, Grid, CardMedia } from '@mui/material';
import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { firestore } from '../../firebase';
import { Lang, LangKeys, languageAtom } from '../../state/controls';
import {
    collection,
    setDoc,
    doc,
    query,
    where,
    getDocs,
    getDoc,
    FieldValue,
    arrayUnion,
    addDoc,
    documentId
} from 'firebase/firestore';
import { Unit } from '../UnitProfile/UnitProfile';
import './Customers.scss';
import { useUserRole } from '../../hooks/useUserRole';
import { Network } from '../Networks/Networks';
import { FormModal } from '../../components/common/FormModal/FormModal';
import { uniqBy } from 'lodash';
import { Project } from '../Projects/Projects';
import { Link, useHistory } from 'react-router-dom';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { toast } from 'react-toastify';

export interface Offer {
    id: string;
    customerId: string;
    unitId: string;
    projectId: string;
    developerId: string;
    realtorId: string;
    priceOffer: string;
    description: string;
    currency: string;
}

export interface CurrentOffer {
    customerId: string;
    unitId: string;
    projectId: string;
    developerId: string;
    realtorId: string;
    currency: string;
}
export interface Customer {
    name?: string;
    phone: string;
    units: string[];
    id: string;
    offers: string[];
}

export const offerFormItems = (lang: Lang, parentCurrency?: string) => [
    {
        name: 'priceOffer',
        label: lang.price_offer,
        additionalLabel: parentCurrency,
        type: 'number',
        required: true,
    },
    {
        name: 'description',
        label: lang.description,
        type: 'text',
    },
];

const Customers: FC = () => {
    const [{ lang }] = useAtom(languageAtom);
    const [customersArray, setCustomersArray] = useState<Customer[]>([]);
    const [realtorOffers, setRealtorOffers] = useState<Offer[]>([]);
    const [currentOfferData, setCurrentOfferData] = useState<CurrentOffer>();
    const [customerUnits, setCustomerUnits] = useState<Unit[]>([]);
    const [customerProjects, setCustomerProjects] = useState<Project[]>([]);
    const [currentProjectCurrency, setCurrentProjectCurrency] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [offerModalOpen, setOfferModalOpen] = useState(false);
    const { fbId } = useUserRole();
    const { push } = useHistory();

    const getCustomers = useCallback(async () => {
        setIsFetching(true);
        let allUnits: Unit[] = [];

        const networksQuery = query(collection(firestore, 'networks'), where('realtors', 'array-contains', fbId));
        const networks = await (await getDocs(networksQuery)).docs;

        const realtorProjects = networks?.map((doc) => (doc.data() as Network).projects).flat();

        const projectsQuery = query(collection(firestore, 'projects'), where(documentId(), 'in', realtorProjects));
        const projects = await (await getDocs(projectsQuery)).docs;

        setCustomerProjects(projects?.map((doc) => ({ ...doc.data(), id: doc.id } as Project)));

        if (realtorProjects?.length) {
            const unitsQuery = query(collection(firestore, 'units'), where('projectId', 'in', realtorProjects));

            const units = await (await getDocs(unitsQuery)).docs;
            const currentUnits = units?.map((doc) => ({ ...doc.data(), id: doc.id } as Unit));

            allUnits = uniqBy([...allUnits, ...currentUnits], 'id');

            const unitIds = units?.map((doc) => doc.id);

            const batches = [];

            while (unitIds.length) {
                const batch = unitIds.splice(0, 10);
                const realtor = (await getDoc(doc(firestore, `realtors/${fbId}`))).data()

                if (realtor?.customers && realtor?.customers.length > 0) {
                    batches.push(query(collection(firestore, 'customers'), where(documentId(), 'in', realtor?.customers)));
                }
            }

            if (batches.length) {
                const customerBatches = await Promise.all(batches.map((query) => getDocs(query)));

                const offerQuery = query(collection(firestore, 'offers'), where('realtorId', '==', fbId));

                const offers = (await getDocs(offerQuery)).docs;

                setRealtorOffers(offers?.map((doc) => ({ ...(doc.data() as Offer), id: doc.id })) || []);

                setCustomersArray(
                    uniqBy(
                        customerBatches
                            .map((batch) => batch.docs)
                            .flat()
                            .map((doc) => ({ ...doc.data(), id: doc.id } as Customer)),
                        (customer) => customer.phone
                    )
                );
            }
        }
        setCustomerUnits(allUnits);

        setIsFetching(false);
    }, [fbId]);

    useEffect(() => {
        getCustomers();
    }, [getCustomers]);

    const renderInterestedUnits = (customer: Customer) => {
        return customer.units.map((unit) => {
            const currentUnit = customerUnits.find((u) => u.id === unit);
            const parentProject = customerProjects.find((p) => p.id === currentUnit?.projectId);

            return (
                <Link to={`/units/${currentUnit?.id}`} key={unit} style={{ textDecoration: 'none' }}>
                    <Card elevation={3} className='unit-card'>
                        {currentUnit?.pics?.length ? (
                            <CardMedia component='img' sx={{ width: 150, height: 150 }} image={currentUnit?.pics?.[0]?.url} />
                        ) : (
                                <div className='no-image'>
                                    <ImageNotSupported className='no-img-icon' />
                                </div>
                            )}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                                <Typography variant='subtitle1' color='text.secondary'>
                                    {lang[currentUnit?.type as LangKeys]}
                                </Typography>
                                <Typography variant='subtitle1' color='text.secondary'>
                                    {lang.project_name}:
                  <Button onClick={() => push(`/projects/${parentProject?.id}`)}>{parentProject?.name}</Button>
                                </Typography>
                            </CardContent>
                            {realtorOffers?.find(({ unitId }) => unitId === currentUnit?.id) ? (
                                <Stack
                                    width='100%'
                                    alignItems='center'
                                    justifyContent='center'
                                    direction='row'
                                    spacing={1}
                                    style={{ color: 'green' }}
                                >
                                    <Check /> <span>{lang.offer_made}</span>
                                </Stack>
                            ) : (
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setOfferModalOpen(true);
                                            setCurrentProjectCurrency(parentProject?.currency || '');
                                            setCurrentOfferData({
                                                customerId: customer.id,
                                                unitId: currentUnit?.id || '',
                                                projectId: parentProject?.id || '',
                                                developerId: parentProject?.developerId || '',
                                                realtorId: fbId,
                                                currency: parentProject?.currency || '',
                                            });
                                        }}
                                        size='large'
                                    >
                                        {lang.make_offer}
                                    </Button>
                                )}
                        </Box>
                    </Card>
                </Link>
            );
        });
    };

    const renderCustomer = customersArray.map((customer, id) => {
        return (
            <Grid item xs={12} sm={6} lg={4} key={id}>
                <Card elevation={3} className='card'>
                    <CardContent>
                        <Stack direction='column' spacing={2}>
                            <Avatar className='icon'>
                                <AccountCircle className='account-icon' />
                            </Avatar>
                            <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                                {lang.phone}: {customer.phone}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                                {lang.interested}:
              </Typography>
                            <div className='units'>{renderInterestedUnits(customer)}</div>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        );
    });

    const onSubmit = async (data: Record<string, FieldValue>) => {
        const { customerId, ...offer } = { ...currentOfferData };

        const newOffer = await addDoc(collection(firestore, 'offers'), { ...offer, customerId, ...data });

        await setDoc(doc(firestore, 'customers', `${customerId}`), { offers: arrayUnion(newOffer.id) }, { merge: true });

        await setDoc(doc(firestore, 'realtors', fbId), { offers: arrayUnion(newOffer.id) }, { merge: true });

        await setDoc(doc(firestore, 'units', `${offer.unitId}`), { status: 'Booked' }, { merge: true });

        toast.success(lang.offer_sent);
        setOfferModalOpen(false);
        getCustomers();

        return Promise.resolve();
    };

    return (
        <div className='customers'>
            {!isFetching ? (
                customersArray?.length ? (
                    <Box style={{ margin: '8px' }}>
                        <Typography sx={{ fontSize: 14, margin: '8px' }} color='text.secondary' gutterBottom>
                            {lang.customers_interested}
                        </Typography>
                        <Grid container spacing={2}>
                            {renderCustomer}
                        </Grid>
                    </Box>
                ) : (
                        <Typography>{lang.no_customers}</Typography>
                    )
            ) : (
                    <PageLoader />
                )}

            <FormModal
                collectionName='offers'
                title={lang.offer_form}
                formItems={offerFormItems(lang, currentProjectCurrency)}
                isOpen={offerModalOpen}
                onClose={() => setOfferModalOpen(false)}
                onSubmit={onSubmit}
            />
        </div>
    );
};

export { Customers };
