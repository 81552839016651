import React, { FC } from 'react';
import { Card, CardContent, CardMedia, Paper, Stack } from '@mui/material';
import Highlight from 'react-highlighter';
import { NavLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { languageAtom } from '../../../state/controls';
import './ElementList.scss';
import { UploadedFile } from '../FormModal/FormModal';

interface ContentValue {
  icon?: JSX.Element;
  value: string | number;
  label?: string;
  additionalLabel?: string;
}

export interface ElementConfig {
  id: string;
  name?: string;
  linkPath: string;
  pics?: UploadedFile[];
  content?: ContentValue[];
}

export interface ElementProps {
  element: ElementConfig;
  highlight?: string;
  className?: string;
}

const ElementList: FC<ElementProps> = ({ element, highlight = '', className }) => {
  const [{ lang }] = useAtom(languageAtom);

  const renderStack = (values?: ContentValue[]) => (
    <div className='render-list'>
      {values?.map(({ icon, value, label, additionalLabel }, ix) => (
        <Stack key={ix} direction='row' spacing={1}>
          {label && (
            <Stack direction='row'>
              <span className='label'>{label}</span>
              <span>{':'}</span>
            </Stack>
          )}
          {icon}
          <span>
            <Highlight search={highlight}>{additionalLabel}</Highlight>
          </span>
          <span className='card-value'>
            <Highlight search={highlight} className='highligh-value'>
              {value}
            </Highlight>
          </span>
        </Stack>
      ))}
    </div>
  );

  const renderCardContent = () => <>{renderStack(element.content)}</>;

  const renderList = () => (
    <Card className='element-list small'>
      {element.pics?.[0] ? (
        <CardMedia component='img' className='image' image={element.pics[0].url} />
      ) : (
        <Paper elevation={0} className='no-media'>
          {lang.no_images}
        </Paper>
      )}
      <CardContent className='content'>{renderCardContent()}</CardContent>
    </Card>
  );

  return (
    <NavLink className={className} style={{ textDecoration: 'none' }} to={`/${element.linkPath}/${element.id}`}>
      {renderList()}
    </NavLink>
  );
};

export { ElementList };
