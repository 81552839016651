import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './Projects.scss';
import { languageAtom } from '../../state/controls';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { ListPage } from '../../components/common/ListPage/ListPage';
import { ElementCard } from '../../components/common/ElementCard/CustomElementCard';
import { Currency } from '../../components/common/ProfilePage/ProfilePage';
import { FormModal, UploadedFile } from '../../components/common/FormModal/FormModal';
import { projectFormItems } from '../ProjectProfile/formsConfig';
import { addDoc, collection, doc, FieldValue, getDoc, getDocs, query, where } from '@firebase/firestore';
import { firestore } from '../../firebase';
import { toast } from 'react-toastify';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { Developer } from '../DeveloperProfile/DeveloperProfile';
import { useUserRole } from '../../hooks/useUserRole';

export interface Project {
    id: string;
    developerId: string;
    name: string;
    cost: string;
    currency: string;
    address: string;
    location: string;
    reservation: string;
    services_ar: string;
    services_en: string;
    city_ar: string;
    city_en: string;
    description_ar: string;
    description_en: string;
    warranties_ar: string;
    warranties_en: string;
    fromTo: DateRange<Date>;
    facilities_ar: string;
    facilities_en: string;
    pics: UploadedFile[];
    logo: UploadedFile[];
    units?: string[];
    active: boolean;
}

const Projects: FC = () => {
    const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);
    const [{ lang, current }] = useAtom(languageAtom);
    const [isFetching, setIsFetching] = useState(false);
    const [developers, setDevelopers] = useState<Developer[]>([]);
    const { isAdmin, isDeveloper, fbId } = useUserRole();

    const fetchDevelopers = useCallback(async () => {
        setIsFetching(true);
        const docs: Developer[] = [];

        if (isDeveloper) {
            const devDoc = await getDoc(doc(firestore, 'developers', fbId));
            docs.push(devDoc.data() as Developer);
        } else {
            const q = query(collection(firestore, 'developers'));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id } as Developer);
            });
        }

        setDevelopers(docs);
        setIsFetching(false);
    }, [fbId, isDeveloper]);

    useEffect(() => {
        fetchDevelopers();
    }, [fetchDevelopers]);

    const projectContentConfig = (project: Project) => {
        return [
            {
                label: "", value: `${project.name}`
            },
            {
                label: "", value: `${current === "ar" ? project.city_ar : project.city_en}`
            },
            {
                label: lang.units, value: `${project.units?.length || 0}`
            }
        ];

    };

    const projectFooterConfigAr = (project: Project) => [{ label: "", value: project.description_ar }];
    const projectFooterConfigEn = (project: Project) => [{ label: "", value: project.description_en }];

    const onSubmit = async (data: Record<string, FieldValue>) => {
        if (isDeveloper) {
            await addDoc(collection(firestore, 'projects'), { ...data, developerId: fbId });
        } else {
            await addDoc(collection(firestore, 'projects'), data);
        }

        toast.success('Project Updated');
        setAddProjectModalOpen(false);
        return Promise.resolve();
    };

    const defaultDeveloper = useMemo(
        () => (isDeveloper ? { value: developers[0]?.id, label: developers[0]?.name } : undefined),
        [developers, isDeveloper]
    );

    return (
        <div className='projects'>
            {!isFetching ? (
                <>
                    <ListPage
                        actionText={lang.new_project}
                        onActionBtnClick={() => setAddProjectModalOpen(true)}
                        collectionName='projects'
                        queryConstraint={isDeveloper ? where('developerId', '==', fbId) : undefined}
                        actionAllowed={isAdmin || isDeveloper}
                        renderElement={(element, highlight: string) => {
                            const project = element as unknown as Project;
                            return (
                                <>
                                    <ElementCard
                                        element={{
                                            linkPath: 'projects',
                                            id: project.id,
                                            name: project.name,
                                            pics: project.pics,
                                            content: projectContentConfig(project),
                                            footer: current == 'ar' ? projectFooterConfigAr(project) : projectFooterConfigEn(project),
                                            active: project.active,
                                            isEmployee: (isAdmin || isDeveloper)

                                        }}
                                        highlight={highlight}
                                    />

                                </>
                            );
                        }}
                    />

                    <FormModal
                        collectionName='projects'
                        title={lang.project_form}
                        formItems={projectFormItems(
                            lang,
                            developers.map(({ id, name }) => ({ label: name, value: id })),
                            defaultDeveloper
                        )}
                        isOpen={addProjectModalOpen}
                        onClose={() => setAddProjectModalOpen(false)}
                        onSubmit={onSubmit}
                    />
                </>
            ) : (
                <PageLoader />
            )}
        </div>
    );
};

export { Projects };
