import { useAtom } from "jotai";
import React, { FC, useCallback, useEffect, useState } from "react";

import {
    Bathroom,
    CropDin,
    CropFree,
    EventBusy,
    Villa,
    Wc,
    Construction,
    DateRange,
    Delete,
    Description,
    Edit,
    ElectricalServices,
    EventAvailable,
    Room,
    SensorDoor,
    MiscellaneousServicesSharp,
    HomeRepairService,
    PublishSharp,
    PublishedWithChangesSharp,
    VideoCall,
    VideoStable,
    VideoStableTwoTone,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WaterIcon from "@mui/icons-material/Water";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BathroomIcon from "@mui/icons-material/Bathroom";
import PublicIcon from "@mui/icons-material/Public";
import PowerIcon from "@mui/icons-material/Power";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import WbSunnyIcon from "@mui/icons-material/WbSunny"
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Grid, IconButton, TextField } from '@mui/material';


import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    DocumentReference,
    FieldValue,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
} from "@firebase/firestore";
import { firestore } from "../../firebase";
import { languageAtom } from "../../state/controls";
import { Project } from "../Projects/Projects";
import { deleteUnit, Unit } from "../UnitProfile/UnitProfile";
import {
    ProfilePage,
    Constraint,
} from "../../components/common/ProfilePage/ProfilePage";
import { ConfirmDialog } from "../../components/common/ConfirmDialog/ConfirmDialog";
import {
    deleteImagesFromStorage,
    formatDateField,
    FormModal,
} from "../../components/common/FormModal/FormModal";
import { Developer } from "../DeveloperProfile/DeveloperProfile";
import { PageLoader } from "../../components/common/PageLoader/PageLoader";
import { projectFormItems, unitFormItems } from "./formsConfig";
import { useUserRole } from "../../hooks/useUserRole";

export const deleteProject = async (projectId: string): Promise<void[]> => {
    const projectDoc = await getDoc(doc(firestore, "projects", projectId));
    const project = { ...projectDoc.data(), id: projectDoc.id } as Project;
    const { id, logo, pics, units } = project;

    const developer = await (
        await getDoc(doc(firestore, "developers", project.developerId))
    ).data();
    const newDeveloperProjects = [...(developer?.projects || [])].filter(
        (projectId) => projectId !== project.id
    );

    if (units?.length) {
        const q = query(
            collection(firestore, "units"),
            where("projectId", "==", id)
        );

        const projectUnits = (await (
            await getDocs(q)
        ).docs.map((unit) => ({ ...unit.data(), id: unit.id }))) as Unit[];

        await Promise.all(projectUnits.map((unit) => deleteUnit(unit)));
    }
    return Promise.all([
        setDoc(
            doc(firestore, "developers", project.developerId),
            { projects: newDeveloperProjects },
            { merge: true }
        ),
        deleteImagesFromStorage("projects", id, "logo", logo),
        deleteImagesFromStorage("projects", id, "pics", pics),
        deleteDoc(doc(firestore, "projects", id)),
    ]);
};

const ProjectProfile: FC = () => {
    const [{ lang, current }] = useAtom(languageAtom);
    const [isDeleting, setIsDeleting] = useState(false);
    const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);
    const [addUnitModalOpen, setAddUnitModalOpen] = useState(false);
    const { replace } = useHistory();
    const [project, setProject] = useState<Project>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [developers, setDevelopers] = useState<Developer[]>([]);
    const { isAdmin, isDeveloper } = useUserRole();
    const [multiValue, setMultiValue] = useState<[number, number]>([0, 0]);
    const [searchTerm, setSearchTerm] = useState('');
    const [checked, setchecked] = useState(true);

    const fetchDevelopers = useCallback(async () => {
        setIsFetching(true);
        const q = query(collection(firestore, "developers"));
        const docs: Developer[] = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id } as Developer);
        });

        setDevelopers(docs);
        setIsFetching(false);
    }, [setIsFetching]);

    useEffect(() => {
        fetchDevelopers();
    }, [fetchDevelopers]);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogYes = async () => {
        setIsDeleting(true);

        await deleteProject((project as Project).id);

        setIsDeleting(false);
        setDialogOpen(false);

        toast.success("Project removed");
        replace("/projects");
    };

    const additionalValues = [
        {
            name: "developer",
            referenceValue: "developerId",
            collectionName: "developers",
        },
        {
            name: "units",
            referenceValue: "units",
            collectionName: "units",
            queryConstraint: ["projectId", "==", `id`] as Constraint,
        },
    ];

    const firstLine = {
        profileAsSource: false,
        label: lang.developer,
        value: "developer.name",
        icon: <Construction />,
        linkBase: "/developers",
        link: "developer.id",
    };

    const mainInfoDevAdmin = {
        label: lang.name,
        value: "name",
    };

    const mainInfo = {
        label: lang.project,
        value: "name",
    };

    const serviceInfo = {
        value: "services",
        label: lang.available_services_nearby,
        items: [
            {
                label: lang.electricity,
                value: "electricity_cables",
                icon: <ElectricalServices />,
            },
            {
                label: lang.street,
                value: "street",
                icon: <HomeRepairService />,
            },
            {
                label: lang.phone,
                value: "phone",
                icon: <PowerIcon />,
            },
            {
                value: "infrastructure",
                label: lang.infrastructure,
                icon: <PowerIcon />
            },
            {
                value: "street_lighting",
                label: lang.street_lighting,
                icon: <LightbulbIcon />
            },
            {
                value: "solar_energy",
                label: lang.solar_energy,
                icon: <WbSunnyIcon />
            },
            {
                value: "more",
                label: lang.more,
                icon: <WbSunnyIcon />
            },
        ],
    };
    const otherInfo = {
        value: "",
        label: "",
        items: [
            {
                label: lang.city,
                value: current == "ar" ? "city_ar" : "city_en",
                icon: <PublicIcon />,
            },
            {
                label: lang.address,
                value: "address",
                icon: <Room />,
            },

            {
                label: `${lang.deliveryDate}`,
                value: "fromTo",
                valueFormatter: formatDateField,
                icon: <DateRange />,
            },
            {
                label: lang.max_reservation,
                value: "reservation",
                additionalLabel: lang.days,
                icon: <EventAvailable />,
            },
            {
                label: lang.description,
                value: current === "ar" ? "description_ar" : "description_en",
                icon: <Description />,
            },
            {
                label: lang.available_services_nearby,
                value: current === "ar" ? "services_ar" : "services_en",
                icon: <ElectricalServices />,
            },
            {
                label: lang.facilities,
                value: current == "ar" ? "facilities_ar" : "facilities_en",
                icon: <PublishedWithChangesSharp />,
            },
        ],
    };

    const listInfo = {
        label: "",
        value: "",
        items: [
            {
                label: lang.electricity,
                value: "electricity",
                icon: <ElectricalServices />,
            },
            {
                label: lang.concrete,
                value: "concrete",
                icon: <HomeRepairService />,
            },
            {
                label: lang.conditioning,
                value: "conditioning",
                icon: <PowerIcon />,
            },
            {
                label: lang.reservoirs,
                value: "reservoirs",
                icon: <WaterIcon />,
            },
            {
                label: lang.paints,
                value: "paints",
                icon: <ColorLensIcon />,
            },
            {
                label: lang.inoutdoor,
                value: "inoutdoor",
                icon: <MeetingRoomIcon />,
            },
            {
                label: lang.sanitary,
                value: "sanitary",
                icon: <MiscellaneousServicesSharp />,
            },
            {
                label: lang.isolation,
                value: "isolation",
                icon: <BakeryDiningIcon />,
            },
            {
                label: lang.plumping,
                value: "plumbing",
                icon: <EngineeringIcon />,
            },
            {
                label: lang.floors_x,
                value: "floors",
                icon: <BathroomIcon />,
            },
        ],
    };

    const profileActions = [
        {
            title: lang.edit_profile,
            onClick: () => setAddProjectModalOpen(true),
            icon: <Edit color="info" />,
            shouldRender: isAdmin || isDeveloper,
        },
        {
            title: lang.delete_profile,
            onClick: () => setDialogOpen(true),
            icon: <Delete color="warning" />,
            shouldRender: isAdmin || isDeveloper,
        },
    ];

    const cardsConfig = {
        profileAsSource: false,
        label: lang.units,
        value: "units",
        link: "units",
        action: {
            title: lang.add_unit,
            onClick: () => setAddUnitModalOpen(true),
        },
        items: [
            {
                value: "status",
                icon: <EventBusy />,
            },
            {
                value: "number",
                icon: <SensorDoor />,
            },
            {
                value: "type",
                icon: <Villa />,
            },
            {
                value: "size",
                additionalLabel: lang.sqm,
                icon: <CropFree />,
            },
            {
                value: "buildArea",
                additionalLabel: lang.sqm,
                icon: <CropDin />,
            },
            {
                value: "bedroom",
                icon: <Wc />,
            },
            {
                value: "bathrooms",
                icon: <Bathroom />,
            },
        ],
    };

    const onSubmit = async (data: Record<string, FieldValue>) => {
        await setDoc(doc(firestore, "projects", `${project?.id}`), data, {
            merge: true,
        });

        toast.success("Project Updated");
        setAddProjectModalOpen(false);
        return Promise.resolve();
    };

    const onUnitSubmit = async (data: Record<string, FieldValue>) => {

        // check the number from the API 

        //1. take the values from the form 
        const idType = ({ ...data } as unknown as Unit).idType // select 
        const idNumber = ({ ...data } as unknown as Unit).idNumber
        const adNumber = ({ ...data } as unknown as Unit).adNumber
        const adStatus = ({ ...data } as unknown as Unit).adStatus

        let idTypeNumber = 1;
        if (idType == "Citizen") {
            idTypeNumber = 1;
        }
        else if (idType == "Resident") {
            idTypeNumber = 2;

        }
        else {

            idTypeNumber = 3;

        }


        if (adStatus == 'Advertiser') {

            //2. do the API Request
            fetch(`https://api.wareef.io/isValidAd?Type_Id=${idTypeNumber}&Id_Number=${idNumber}&Ad_Number=${adNumber}`)
                .then(
                    async function (res) {
                        if (res.ok == true) {
                            toast.success("تم التحقق من خدمة عقاري");
                            const [from, to] = multiValue;
                            let newUnits: DocumentReference[] = [];

                            if (from !== to) {
                                const unitSharedId = `${project?.id}__${({ ...data } as unknown as Unit).floor
                                    }`;
                                const multipleUnits: Unit[] = [];

                                for (let i = from; i < to; i++) {
                                    const currentUnit = {
                                        ...data,
                                        number: `${i}`,
                                        unitSharedId,
                                        projectId: project?.id,
                                    } as Unit;
                                    multipleUnits.push(currentUnit);
                                }

                                newUnits = await Promise.all(
                                    multipleUnits.map((data) =>
                                        addDoc(collection(firestore, "units"), data)
                                    )
                                );
                            } else {
                                newUnits = [
                                    await addDoc(collection(firestore, "units"), {
                                        ...data,
                                        projectId: project?.id,
                                    }),
                                ];
                            }
                            await setDoc(
                                doc(firestore, "projects", `${project?.id}`),
                                {
                                    units: [...(project?.units || []), ...newUnits.map((doc) => doc.id)],
                                },
                                { merge: true }
                            );
                            toast.success("Project updated with ne,w unit(s)");
                            setAddUnitModalOpen(false);
                            return Promise.resolve();
                        }


                        return res.json()

                    }
                )
                .then(async (json) => {
                    if (json === undefined || json === null) {
                        console.log("g");
                    }
                    else {
                        toast.error(json.errorMsg_AR);
                    }





                });
        }
        else {

            let authNumber = ({ ...data } as unknown as Unit).authNumber


            if (authNumber == "") {
                authNumber = "000"

            }

            //2. do the API Request
            fetch(`https://api.wareef.io/isValidAuthAd?Type_Id=${idTypeNumber}&Id_Number=${idNumber}&Auth_Number=${authNumber}&Ad_Number=${adNumber}`)
                .then(
                    async function (res) {
                        if (res.ok == true) {
                            toast.success("تم التحقق من خدمة عقاري");
                            const [from, to] = multiValue;
                            let newUnits: DocumentReference[] = [];

                            if (from !== to) {
                                const unitSharedId = `${project?.id}__${({ ...data } as unknown as Unit).floor
                                    }`;
                                const multipleUnits: Unit[] = [];

                                for (let i = from; i < to; i++) {
                                    const currentUnit = {
                                        ...data,
                                        number: `${i}`,
                                        unitSharedId,
                                        projectId: project?.id,
                                    } as Unit;
                                    multipleUnits.push(currentUnit);
                                }

                                newUnits = await Promise.all(
                                    multipleUnits.map((data) =>
                                        addDoc(collection(firestore, "units"), data)
                                    )
                                );
                            } else {
                                newUnits = [
                                    await addDoc(collection(firestore, "units"), {
                                        ...data,
                                        projectId: project?.id,
                                    }),
                                ];
                            }
                            await setDoc(
                                doc(firestore, "projects", `${project?.id}`),
                                {
                                    units: [...(project?.units || []), ...newUnits.map((doc) => doc.id)],
                                },
                                { merge: true }
                            );
                            toast.success("Project updated with ne,w unit(s)");
                            setAddUnitModalOpen(false);
                            return Promise.resolve();
                        }


                        return res.json()

                    }
                )
                .then(async (json) => {
                    if (json === undefined || json === null) {
                        console.log("g");
                    }
                    else {
                        toast.error(json.errorMsg_AR);
                    }





                });

        }
    };

    const { id: value, name: label } = {
        ...developers.find(({ id }) => id === project?.developerId),
    } as Developer;

    return (
        <div style={{ height: "100%" }}>
            {!isFetching ? (
                <>
                    {isDeveloper || isAdmin ? (
                        <ProfilePage
                            collectionName="projects"
                            additionalCollections={additionalValues}
                            firstLine={firstLine}
                            mainInfo={mainInfoDevAdmin}
                            serviceInfo={serviceInfo}
                            otherInfo={otherInfo}
                            listInfo={listInfo}
                            /*              listMoreWarranties={listMoreWarranties}*/
                            cardsConfig={cardsConfig}
                            profileActions={profileActions}
                            withMap
                            getProfile={(profile) => setProject(profile as Project)}
                        />

                    ) : (
                            <ProfilePage
                                collectionName="projects"
                                additionalCollections={additionalValues}
                                firstLine={firstLine}
                                mainInfo={mainInfo}
                                serviceInfo={serviceInfo}
                                otherInfo={otherInfo}
                                listInfo={listInfo}
                                /*              listMoreWarranties={listMoreWarranties}*/
                                cardsConfig={cardsConfig}
                                profileActions={profileActions}
                                withMap
                                getProfile={(profile) => setProject(profile as Project)}
                            />
                        )}

                    <FormModal
                        collectionName="projects"
                        title={lang.project_form}
                        formItems={projectFormItems(
                            lang,
                            developers.map(({ id, name }) => ({ label: name, value: id })),
                            { value, label }
                        )}
                        isOpen={addProjectModalOpen}
                        onClose={() => setAddProjectModalOpen(false)}
                        onSubmit={onSubmit}
                        defaultValues={project}
                        defaultFiles={{ logo: project?.logo, pics: project?.pics }}
                    />
                    <FormModal
                        collectionName="units"
                        title={lang.unit_form}
                        formItems={unitFormItems(lang, project?.currency as string)}
                        multiConfig={{
                            incrementField: "number",
                            onValueChange: (from: number, to: number) => {
                                setMultiValue([from, to]);
                            },
                        }}
                        isOpen={addUnitModalOpen}
                        onClose={() => setAddUnitModalOpen(false)}
                        onSubmit={onUnitSubmit}
                    />

                    <ConfirmDialog
                        title={lang.confirm_delete_project}
                        loading={isDeleting}
                        loadingText={lang.working_delete}
                        onClose={handleDialogClose}
                        onYes={handleDialogYes}
                        isOpen={dialogOpen}
                    />
                </>
            ) : (
                    <PageLoader />
                )}
        </div>
    );
};

export { ProjectProfile };
