import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "@firebase/app";

import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD07FQws1IbpchSfEUSMvA3k-rURu9DBk0",
  authDomain: "wareef-344cf.firebaseapp.com",
  databaseURL:
    "https://wareef-344cf-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wareef-344cf",
  storageBucket: "wareef-344cf.appspot.com",
  messagingSenderId: "530938934772",
  appId: "1:530938934772:web:94e95864328b8ae40a35a1",
  measurementId: "G-1SFJLQS29J",
};

const fire = initializeApp(firebaseConfig);
const auth = getAuth();

auth.useDeviceLanguage();

const firestore = getFirestore();
const storage = getStorage();

const appVerifier = (
  type: "invisible" | "recaptcha-container"
): RecaptchaVerifier =>
  new RecaptchaVerifier(
    type,
    {
      size: "normal",
    },
    auth
  );

export { fire, auth, firestore, storage, appVerifier };
