import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { auth } from './firebase';
import { authInitializedAtom } from './state/auth';

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const [authInitialized] = useAtom(authInitializedAtom);

  return (
    (
      <Route
        {...rest}
        render={(props) =>
          authInitialized && auth.currentUser !== null && auth.currentUser.email && Component ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }
      />
    ) || null
  );
};

export { PrivateRoute };
