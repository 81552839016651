import { useAtom } from 'jotai';
import { useParams } from 'react-router';
import { UserData, userDataAtom } from '../state/auth';

interface IuseUserRole {
  isAdmin: boolean;
  isRealtor: boolean;
  isDeveloper: boolean;
  isCustomer: boolean;
  isOwnDocument: boolean;
  role: string;
  uid: string;
  fbId: string;
  phone: string;
}

export const useUserRole = (): IuseUserRole => {
  const [userData] = useAtom(userDataAtom);
  const { id } = useParams<{ id: string }>();

  const { role, uid, fbId, phone } = (userData || {}) as UserData & {
    role: string;
    uid: string;
    fbId: string;
    phone: string;
  };

  const isAdmin = role === 'admin';
  const isRealtor = role === 'realtor';
  const isDeveloper = role === 'developer';
  const isCustomer = role === 'customer';
  const isOwnDocument = id === userData?.fbId;

  return { isAdmin, isRealtor, isDeveloper, isCustomer, isOwnDocument, role, uid, fbId, phone };
};
