import { AccountCircle } from '@mui/icons-material';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { UploadedFile } from '../../components/common/FormModal/FormModal';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { useUserRole } from '../../hooks/useUserRole';
import { UserData, userDataAtom } from '../../state/auth';
import { languageAtom } from '../../state/controls';
import './Dashboard.scss';

const Dashboard: FC = () => {
  const [userData] = useAtom(userDataAtom);
  const [{ lang }] = useAtom(languageAtom);
  const { isAdmin } = useUserRole();

  const { name, fbId, pics, role } = { ...userData } as UserData & { name: string; pics: UploadedFile[] };

  return userData !== null ? (
    <div className='dashboard'>
      <Typography variant='h3' sx={{ marginBottom: '16px' }}>
        <Stack spacing={1}>
          <span>{lang.welcome}</span> <span>{!isAdmin ? name : lang.admin}</span>
        </Stack>
      </Typography>

      <>
        <div className='avatar'>
          <Link className='link' to={`/${role}s/${fbId}`}>
            {pics?.length ? (
              <Avatar className='icon' src={pics[0].url} alt={name} />
            ) : (
              <Avatar className='icon'>
                <AccountCircle className='account-icon' />
              </Avatar>
            )}
          </Link>
          <Divider className='divider' />
        </div>
      </>
    </div>
  ) : (
    <PageLoader />
  );
};

export { Dashboard };
