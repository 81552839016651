import { AccountTree, Delete, Edit, Work } from '@mui/icons-material';
import { collection, deleteDoc, doc, FieldValue, getDocs, query, setDoc } from '@firebase/firestore';
import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfilePage } from '../../components/common/ProfilePage/ProfilePage';
import { firestore } from '../../firebase';
import { languageAtom } from '../../state/controls';
import { ConfirmDialog } from '../../components/common/ConfirmDialog/ConfirmDialog';
import { deleteImagesFromStorage, FormModal, UploadedFile } from '../../components/common/FormModal/FormModal';
import { Network, networkFormItems } from '../Networks/Networks';
import { Project } from '../Projects/Projects';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { useUserRole } from '../../hooks/useUserRole';

export interface Realtor {
  name: string;
  website: string;
  phone: string;
  description: string;
  photo: UploadedFile[];
  id: string;
  type: 'contractor' | 'freelance';
  uid: string;
}

const NetworkProfile: FC = () => {
  const [{ lang }] = useAtom(languageAtom);
  const { id } = useParams<{ id: string }>();
  const [network, setNetwork] = useState<Network>();
  const [addNetworkModalOpen, setAddNetworkModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { replace } = useHistory();
  const [projects, setProjects] = useState<Project[]>([]);
  const [realtors, setRealtors] = useState<Realtor[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { isAdmin, isDeveloper } = useUserRole();

  const fetchOptions = useCallback(async () => {
    setIsFetching(true);

    const q = query(collection(firestore, 'projects'));
    const docs: Project[] = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id } as Project);
    });

    const qRealtors = query(collection(firestore, 'realtors'));
    const docsRealtors: Realtor[] = [];
    const querySnapshotRealtors = await getDocs(qRealtors);

    querySnapshotRealtors.forEach((doc) => {
      docsRealtors.push({ ...doc.data(), id: doc.id } as Realtor);
    });

    setProjects(docs);
    setRealtors(docsRealtors);

    setIsFetching(false);
  }, [setIsFetching]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handleDialogYes = async () => {
    const { logo } = network as Network;

    setIsDeleting(true);

    await deleteImagesFromStorage('networks', id, 'logo', logo);
    await deleteDoc(doc(firestore, 'networks', id));

    setIsDeleting(false);
    setDialogOpen(false);

    toast.success('Network profile removed');

    replace(`/networks`);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const mainInfo = {
    label: lang.description,
    value: 'description',
  };

  const otherInfo = {
    value: '',
    label: '',
    items: [
      {
        label: lang.projects,
        value: 'projects.length',
        icon: <Work />,
      },
      {
        label: lang.realtors,
        value: 'realtors.length',
        icon: <AccountTree />,
      },
    ],
  };

  const profileActions = [
    {
      title: lang.edit_profile,
      onClick: () => setAddNetworkModalOpen(true),
      icon: <Edit color='info' />,
      shouldRender: isAdmin || isDeveloper,
    },
    {
      title: lang.delete_profile,
      onClick: () => setDialogOpen(true),
      icon: <Delete color='warning' />,
      shouldRender: isAdmin || isDeveloper,
    },
  ];

  const onSubmit = async (data: Record<string, FieldValue>) => {
    await setDoc(doc(firestore, 'networks', `${network?.id}`), data, { merge: true });

    toast.success('Network profile info updated');

    return Promise.resolve();
  };

  const defaultProjects = projects
?.filter(({ id }) => network?.projects?.includes(id))
    .map(({ id, name }) => ({ label: name, value: id }));

  const defaultRealtors = realtors
    ?.filter(({ id }) => network?.realtors?.includes(id))
    .map(({ id, name }) => ({ label: name, value: id }));

  return (
    <div style={{ height: '100%' }}>
      {!isFetching ? (
        <>
          <ProfilePage
            collectionName='networks'
            mainInfo={mainInfo}
            otherInfo={otherInfo}
            profileActions={profileActions}
            getProfile={(profile) => setNetwork(profile as Network)}
          />

          <FormModal
            collectionName='networks'
            title={lang.network_form}
            formItems={networkFormItems(
              lang,
              projects.map(({ id, name }) => ({ label: name, value: id })),
              realtors.map(({ id, name }) => ({ label: name, value: id })),
              defaultProjects,
              defaultRealtors
            )}
            isOpen={addNetworkModalOpen}
            onClose={() => setAddNetworkModalOpen(false)}
            onSubmit={onSubmit}
            defaultValues={network}
            defaultFiles={{ logo: network?.logo }}
          />

          <ConfirmDialog
            title={lang.confirm_delete_network}
            loading={isDeleting}
            loadingText={lang.working_delete}
            onClose={handleDialogClose}
            onYes={handleDialogYes}
            isOpen={dialogOpen}
          />
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export { NetworkProfile };
