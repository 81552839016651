import { CircularProgress, Stack } from '@mui/material';
import React, { FC } from 'react';

const PageLoader: FC = () => {
  return (
    <Stack width='100%' minHeight='50vh' height='100%' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Stack>
  );
};

export { PageLoader };
