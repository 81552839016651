import { Button, Grid, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { languageAtom, switchLanguageAtom } from '../../state/controls';
import { linkWithCredential, EmailAuthProvider } from 'firebase/auth';
import './Register.scss';
import { auth, firestore } from '../../firebase';
import { toast } from 'react-toastify';
import { collection, getDocs, query, where, doc, setDoc, deleteDoc, addDoc } from '@firebase/firestore';
import { FirebaseError } from '@firebase/util';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { activeRouteAtom } from '../../state/route';
import { userDataAtom } from '../../state/auth';

interface PreRegisterData {
  phone: string;
  role: string;
}

interface NewUserData {
  name: string;
  phone: string;
  role: string;
  id: string;
}

interface FormData {
  email: string;
  password: string;
  name: string;
  address: string;
  gender: string;
  age: number;
}

const Register: FC = () => {
  const [{ lang, rtlOn }] = useAtom(languageAtom);
  const [, switchLanguage] = useAtom(switchLanguageAtom);
  const { state = { phone: '', role: '' } } = useLocation<PreRegisterData>();
  const [isFetching, setIsFetching] = useState(false);
  const { replace } = useHistory();
  const { handleSubmit, register } = useForm<FormData>({ mode: 'onBlur' });
  const [newUserData, setNewUserData] = useState<NewUserData>();
  const [, setActiveRoute] = useAtom(activeRouteAtom);
  const [, setUserData] = useAtom(userDataAtom);

  const fetchUserData = useCallback(async () => {
    if (state.phone && state.role) {
      setIsFetching(true);
      const q = query(collection(firestore, `${state.role}s`), where('phone', '==', state.phone));

      const [userData] = (await getDocs(q)).docs;
      if (userData?.exists()) {
        setNewUserData({ ...userData.data(), id: userData.id } as NewUserData);

        setIsFetching(false);
      } else {
        toast.error('User not found');
      }
    }
  }, [state.phone, state.role]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const onSubmit = handleSubmit(async ({ email, password, name, address, gender, age }) => {
    if (auth.currentUser) {
      setIsFetching(true);

      const credential = EmailAuthProvider.credential(email, password);

      try {
        await linkWithCredential(auth.currentUser, credential);
        const q = query(collection(firestore, 'roles'), where('phone', '==', state.phone));

        const [roleDoc] = (await getDocs(q)).docs;

        if (roleDoc?.exists()) {
          await deleteDoc(roleDoc.ref);
          await setDoc(doc(firestore, 'roles', auth.currentUser.uid), {
            ...roleDoc.data(),
          });
        }

        if (state.role === 'customer' || !state.role) {
          const customerData = {
            email,
            name,
            address,
            gender,
            age,
            phone: state?.phone,
            createdAt: new Date().toISOString(),
          };

          if (roleDoc?.exists()) {
            await setDoc(doc(firestore, 'customers', roleDoc.data().fbId), { ...customerData }, { merge: true });
          } else {
            const newCustomer = await addDoc(collection(firestore, 'customers'), { ...customerData });
            await setDoc(doc(firestore, 'roles', auth.currentUser.uid), {
              phone: customerData.phone,
              fbId: newCustomer.id,
              role: 'customer',
            });
          }
        }

        toast.success('User profile activated');
        replace('/dashboard');
        setActiveRoute('dashboard');
      } catch (error) {
        const fbError = error as FirebaseError;
        toast.error(fbError.message || fbError);
      }

      setIsFetching(false);
    }
  });

  if (isEmpty(state)) {
    replace('/login');
  }

  return (
    <div dir={rtlOn ? 'rtl' : 'ltr'} className='register'>
      {!isFetching ? (
        <Stack direction='column' alignItems='center' justifyContent='space-evenly'>
          <IconButton className={'lang-switch'} onClick={switchLanguage}>
            {lang.lang}
          </IconButton>
          <Typography textAlign='center' color='white' variant='h3'>
            {lang.register_title}
          </Typography>
          {newUserData?.name && (
            <Paper className='box'>
              <Grid container spacing={2}>
                <Grid xs={6} item>
                  {lang.inactive_user}
                </Grid>
                <Grid xs={6} item>
                  {newUserData?.name}
                </Grid>
                <Grid xs={6} item>
                  {lang.phone}
                </Grid>
                <Grid xs={6} item>
                  {newUserData?.phone}
                </Grid>
              </Grid>
            </Paper>
          )}
          <form onSubmit={onSubmit}>
            <Paper className='box'>
              <Stack direction='column' spacing={2} width='90%'>
                {(state.role === 'customer' || !state.role) && (
                  <>
                    <TextField
                      {...register('name')}
                      fullWidth
                      required={!state.role}
                      label={lang.name}
                      name='name'
                      type='text'
                      variant='outlined'
                    />
                    <TextField
                      {...register('address')}
                      fullWidth
                      required={!state.role}
                      label={lang.address}
                      name='address'
                      type='text'
                      variant='outlined'
                    />

                    <Select
                      className='select'
                      required={!state.role}
                      defaultValue='male'
                      {...register('gender')}
                      label={lang.gender}
                    >
                      <MenuItem value={'male'}>{lang.male}</MenuItem>
                      <MenuItem value={'female'}>{lang.female}</MenuItem>
                    </Select>
                    <TextField
                      {...register('age')}
                      fullWidth
                      required={!state.role}
                      label={lang.age}
                      name='age'
                      type='number'
                      variant='outlined'
                    />
                  </>
                )}
                <TextField
                  {...register('email')}
                  fullWidth
                  required
                  label={lang.email}
                  name='email'
                  type='email'
                  variant='outlined'
                />
                <TextField
                  {...register('password')}
                  fullWidth
                  required
                  label={lang.password}
                  name='password'
                  type='password'
                  variant='outlined'
                />
                <Button type='submit' color='secondary' variant='contained' size='large'>
                  {lang.register}
                </Button>
              </Stack>
            </Paper>
          </form>
        </Stack>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export { Register };
