import ReactPhoneInput from 'react-phone-input-mui';
import { FirebaseError } from '@firebase/util';
import { Button, Grid, TextField, CircularProgress, Link, IconButton, Tabs, Tab } from '@mui/material';
import classNames from 'classnames';
import {
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  ConfirmationResult,
  UserCredential,
  User,
} from 'firebase/auth';
import { query, collection, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { useAtom } from 'jotai';
import React, { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../assets/logo-purple.png';
import sparkles from '../../assets/sparkles.png';
import { appVerifier, auth, firestore } from '../../firebase';
import './Login.scss';
import { languageAtom, switchLanguageAtom } from '../../state/controls';
import { Box } from '@mui/system';
import { authInitializedAtom, userDataAtom } from '../../state/auth';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { activeRouteAtom } from '../../state/route';

interface FormData {
  phone: string;
  code: string;
  email: string;
  password: string;
}

const Login: FC = () => {
  const [authInitialized] = useAtom(authInitializedAtom);
  const [{ lang, rtlOn }] = useAtom(languageAtom);
  const [, switchLanguage] = useAtom(switchLanguageAtom);
  const [isFetching, setIsFetching] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [hideCaptcha, setHideCaptcha] = useState(true);
  const { handleSubmit, register, reset, control } = useForm<FormData>({ mode: 'onBlur' });
  const { push } = useHistory();
  const [, setActiveRoute] = useAtom(activeRouteAtom);
  const [userData] = useAtom(userDataAtom);

  const confirmation = useRef<ConfirmationResult>();

  const setUserData = async (user: User, phone?: string) => {
    try {
      if (!user.email) {
        const { uid } = user;
        const q = query(collection(firestore, 'roles'), where('phone', '==', phone));
        const [newUser] = (await getDocs(q)).docs;

        if (newUser?.exists()) {
          push('/register', { ...newUser.data() });
        } else {
          push('/register', { uid, phone });
        }
      } else {
        const q = query(collection(firestore, 'roles'), where('phone', '==', phone));
        const [newUser] = (await getDocs(q)).docs;

        if (!newUser?.exists()) {
          toast.error(lang.user_deactivated);
        } else {
          push('/dashboard');
          setActiveRoute('dashboard');
        }
      }
    } catch (error: unknown) {
      const { code, message } = error as FirebaseError;
      toast.error(code || message);
    }
  };

  const onSubmit = handleSubmit(async ({ phone, code, email, password }) => {
    setIsFetching(true);

    try {
      if (phone && !code) {
        setHideCaptcha(false);

        await signInWithPhoneNumber(auth, phone, appVerifier('recaptcha-container')).then((confirmationResult) => {
          confirmation.current = confirmationResult;
          setHideCaptcha(true);
          setShowCodeInput(true);
          reset({ phone });
        });
      } else if (code && confirmation.current) {
        await confirmation.current
          .confirm(code)
          .then((result) => setUserData(result.user, phone))
          .catch((error) => {
            const { code } = error as FirebaseError;
            const message: Record<string, string> = {
              'auth/user-not-found': lang.user_not_found,
              'auth/wrong-password': lang.wrong_password,
              'auth/invalid-verification-code': lang.invalid_code,
              'auth/code-expired': lang.expired_code,
              'auth/invalid-phone-number': lang.invalid_phone,
            };
            setHideCaptcha(true);
            reset();
            toast.error(message[code] || code);
          });
      } else if (email && password) {
        const data: UserCredential = await signInWithEmailAndPassword(auth, email, password);

        const userRole = await getDoc(doc(firestore, 'roles', data.user.uid));

        if (userRole?.exists()) {
          push('/dashboard');
          setActiveRoute('dashboard');
        } else {
          toast.error(lang.user_deactivated);
        }
      }
    } catch (error: unknown) {
      const { code } = error as FirebaseError;
      const message: Record<string, string> = {
        'auth/user-not-found': lang.user_not_found,
        'auth/wrong-password': lang.wrong_password,
        'auth/invalid-verification-code': lang.invalid_code,
        'auth/code-expired': lang.expired_code,
        'auth/invalid-phone-number': lang.invalid_phone,
      };
      setHideCaptcha(true);
      reset();
      toast.error(message[code] || code);
    }

    reset();
    setIsFetching(false);
  });

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    reset({});
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const renderPhoneForm = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!showCodeInput ? (
              <Controller
                control={control}
                name='phone'
                render={({ field: { value, onChange } }) => {
                  return (
                    <ReactPhoneInput
                      defaultCountry='sa'
                      component={TextField}
                      value={value}
                      onChange={onChange}
                      inputExtraProps={{
                        required: true,
                        label: lang.phone,
                      }}
                    />
                  );
                }}
              />
            ) : (
              <>
                <p>{lang.code_sent}</p>
                <TextField
                  {...register('code')}
                  fullWidth
                  required={showCodeInput}
                  label={lang.code}
                  name='code'
                  variant='outlined'
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderCredentialsForm = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          {...register('email')}
          fullWidth
          required={value === 1}
          label={lang.email}
          name='email'
          type='email'
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register('password')}
          fullWidth
          required={value === 1}
          label={lang.password}
          name='password'
          type='password'
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12}>
        <div className='footer'>
          <Link className='link' onClick={() => toast.info('TODO: reset password flow')}>
            {lang.forgot_password}
          </Link>
        </div>
      </Grid>
    </Grid>
  );

  return authInitialized ? (
    userData === null ? (
      <div className='login'>
        <img src={sparkles} className='sparkles' />
        <div className='logo-wrapper'>
          <img src={logo} alt='waref' className='logo' />
        </div>
        <div className='login-container' dir={rtlOn ? 'rtl' : 'ltr'}>
          <Box sx={{ marginBottom: 1 }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={lang.phone} />
              <Tab label={lang.login_credentials} />
            </Tabs>
          </Box>
          <form onSubmit={onSubmit}>
            <TabPanel value={value} index={0}>
              {renderPhoneForm()}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderCredentialsForm()}
            </TabPanel>
            <Button
              id='sign-in-button'
              color='secondary'
              fullWidth
              type='submit'
              variant='contained'
              className={classNames('btn', { btn__fetching: isFetching })}
            >
              {isFetching ? <CircularProgress className='fetching' color='info' size={24} /> : lang.log_in}
            </Button>
            <div className='controls'>
              <IconButton color='secondary' onClick={switchLanguage}>
                {lang.lang}
              </IconButton>
            </div>
            {!hideCaptcha && <div id='recaptcha-container'></div>}
          </form>
        </div>
      </div>
    ) : (
      <Redirect to='/dashboard' />
    )
  ) : (
    <PageLoader />
  );
};

export { Login };
