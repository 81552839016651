import { Delete, Edit, Message, Phone, Web } from "@mui/icons-material";
import { deleteDoc, doc, FieldValue, setDoc } from "@firebase/firestore";
import { Unsubscribe } from "@firebase/util";
import { useAtom } from "jotai";
import React, { FC, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProfilePage } from "../../components/common/ProfilePage/ProfilePage";
import { firestore } from "../../firebase";
import { Lang, languageAtom } from "../../state/controls";
import { deleteProject } from "../ProjectProfile/ProjectProfile";
import { ConfirmDialog } from "../../components/common/ConfirmDialog/ConfirmDialog";
import {
    deleteImagesFromStorage,
    FormItem,
    FormModal,
    UploadedFile,
} from "../../components/common/FormModal/FormModal";
import { useUserRole } from "../../hooks/useUserRole";

export const developerFormItems = (lang: Lang): FormItem[] => [
    {
        name: "name",
        label: lang.name,
        type: "text",
        required: true,
    },
    {
        name: "phone",
        label: lang.phone,
        type: "phone",
        required: true,
    },
    {
        name: "email",
        label: lang.email,
        type: "text",
        required: true,
    },
    {
        name: "website",
        label: lang.website,
        type: "url",
        required: true,
    },
    {
        name: "description",
        label: lang.description,
        type: "text",
        required: true,
    },
    {
        name: "logo",
        label: lang.logo,
        type: "image",
        required: true,
    },
];

export interface Developer {
    name: string;
    website: string;
    phone: string;
    description: string;
    logo: UploadedFile[];
    projects: string[];
    id: string;
    uid: string;
}

const DeveloperProfile: FC = () => {
    const [{ lang }] = useAtom(languageAtom);
    const unsubscribe = useRef<Unsubscribe>();
    const { id } = useParams<{ id: string }>();
    const [developer, setDeveloper] = useState<Developer>();
    const [addDeveloperModalOpen, setAddDeveloperModalOpen] = useState(false);
    const { replace } = useHistory();
    const { isAdmin, isOwnDocument } = useUserRole();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDialogYes = async () => {
        const { logo, projects } = developer as Developer;

        unsubscribe.current?.();
        setIsDeleting(true);

        if (projects?.length) {
            await Promise.all((developer?.projects as string[]).map(deleteProject));
        }

        await deleteImagesFromStorage("developers", id, "logo", logo);
        await deleteDoc(doc(firestore, "developers", id));
        await deleteDoc(doc(firestore, "users", id));

        setIsDeleting(false);
        setDialogOpen(false);

        toast.success("Developer profile removed");

        replace(`/developers`);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const additionalValues = [
        {
            name: "projects",
            referenceValue: "projects",
            collectionName: "projects",
        },
    ];

    const mainInfo = {
        label: lang.description,
        value: "description",
    };

    const otherInfo = {
        value: "",
        label: "",
        items: [
            {
                label: lang.website,
                value: "website",
                icon: <Web />,
            },
            {
                label: lang.phone,
                value: "phone",
                icon: <Phone />,
            },
            {
                label: lang.email,
                value: "email",
                icon: <Message />,
            },
        ],
    };

    const profileActions = [
        {
            title: lang.edit_profile,
            onClick: () => setAddDeveloperModalOpen(true),
            icon: <Edit color="info" />,
            shouldRender: isAdmin || isOwnDocument,
        },
        {
            title: lang.delete_profile,
            onClick: () => setDialogOpen(true),
            icon: <Delete color="warning" />,
            shouldRender: isAdmin || isOwnDocument,
        },
    ];

    const cardsConfig = {
        profileAsSource: false,
        label: lang.projects,
        value: "projects",
        link: "projects",
        noDataMessage: lang.no_dev_projects,
        items: [
            {
                label: lang.name,
                value: "name",
            },
            {
                label: lang.cost,
                value: "cost",
            },
            {
                label: lang.units,
                value: "units",
            },
        ],
    };

    const onSubmit = async (data: Record<string, FieldValue>) => {
        await setDoc(doc(firestore, "developers", `${developer?.id}`), data, {
            merge: true,
        });

        if (developer?.uid) {
            await setDoc(
                doc(firestore, "roles", developer.uid),
                {
                    phone: data.phone,
                },
                { merge: true }
            );
        }

        toast.success("Developer profile info updated");

        return Promise.resolve();
    };

    return (
        <div style={{ height: "100%" }}>
            <ProfilePage
                collectionName="developers"
                additionalCollections={additionalValues}
                mainInfo={mainInfo}
                otherInfo={otherInfo}
                cardsConfig={cardsConfig}
                profileActions={profileActions}
                getProfile={(profile) => setDeveloper(profile as Developer)}
            />
            <FormModal
                collectionName="developers"
                title={lang.developer_form}
                formItems={developerFormItems(lang)}
                defaultValues={developer}
                defaultFiles={{ logo: developer?.logo }}
                isOpen={addDeveloperModalOpen}
                onClose={() => setAddDeveloperModalOpen(false)}
                onSubmit={onSubmit}
            />
            <ConfirmDialog
                title={lang.confirm_delete_developer}
                loading={isDeleting}
                loadingText={lang.working_delete}
                onClose={handleDialogClose}
                onYes={handleDialogYes}
                isOpen={dialogOpen}
            />
        </div>
    );
};

export { DeveloperProfile };
