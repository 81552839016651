import React, { FC, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import Highlight from 'react-highlighter';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { languageAtom } from '../../../state/controls';
import './ElementCard.scss';
import { UploadedFile } from '../FormModal/FormModal';

interface ContentValue {
    value: string | number;
    label: string;
    additionalLabel?: string;
}

export interface ElementConfig {
    id: string;
    name?: string;
    user_role?: string;
    linkPath: string;
    pics?: UploadedFile[];
    content?: ContentValue[];
    footer?: ContentValue[];
    active?: boolean;
    isEmployee: boolean;
}

export interface ElementProps {
    small?: boolean;
    element: ElementConfig;
    highlight?: string;
    className?: string;
}

const ElementCard: FC<ElementProps> = ({ element, highlight = '', className }) => {
    const [{ lang }] = useAtom(languageAtom);
    const [imageLoaded, setImageLoaded] = useState(false);

    const renderStack = (values?: ContentValue[], direction?: 'column') => (
        <>

            <Stack direction={direction || 'row'} spacing={1}>
                {values?.map(({ value, label, additionalLabel }, ix) => (
                    <Stack key={ix} direction='row' spacing={1}>
                        {label && (
                            <Stack direction='column'>
                                <span className='label'>{label} :</span>
                            </Stack>
                        )}
                        <span>
                            <Highlight search={highlight}>{additionalLabel}</Highlight>
                        </span>
                        <span className='card-value'>
                            <Highlight search={highlight}>{value}</Highlight>
                        </span>

                    </Stack>
                ))}
            </Stack>
        </>
    );

    const renderCardContent = (direction?: 'column') => (
        <>
            {renderStack(element.content, direction)}
            {element.footer && (
                <Typography variant='subtitle2' color='text.secondary'>
                    {renderStack(element.footer, direction)}
                </Typography>
            )}
        </>
    );

    const renderBigCard = () => (
        <Card elevation={3} id={element.id} className='element-card'>

            {element.pics?.length ? (
                <>
                    {!imageLoaded && (
                        <div className='image-loading'>
                            <CircularProgress />
                        </div>
                    )}
                    <CardMedia
                        className={classNames({ loading: !imageLoaded })}
                        component='img'
                        height='194'
                        image={element.pics[0].url}
                        onLoad={() => setImageLoaded(true)}
                    />
                </>
            ) : (
                <Paper className='no-media'>{lang.no_images}</Paper>
            )}

            <CardContent>{renderCardContent()}</CardContent>
            {element.isEmployee ? (

                <CardActions>
                    {element.active ? (
                        <Button size="small">{lang.enable}</Button>
                    ) : (
                        <Button size="small">{lang.disable}</Button>
                    )}
                </CardActions>
            ) : ""}
        </Card>
    );

    return (
        <NavLink className={className} style={{ textDecoration: 'none' }} to={`/${element.linkPath}/${element.id}`}>
            {renderBigCard()}
        </NavLink>
    );
};

export { ElementCard };
