import React, { FC, useEffect, useLayoutEffect, useRef } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppContent } from './AppContent';
import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { languageAtom } from './state/controls';
import { useAtom } from 'jotai';
import { auth, firestore } from './firebase';
import { authInitializedAtom, RoleData, userDataAtom, UserData } from './state/auth';
import { getDoc, doc, onSnapshot } from '@firebase/firestore';
import { get } from 'lodash';
import { UploadedFile } from './components/common/FormModal/FormModal';
import { Unsubscribe } from '@firebase/util';

const App: FC = () => {
  const [, setAuthInitialized] = useAtom(authInitializedAtom);
  const [, setUserData] = useAtom(userDataAtom);
  const [{ rtlOn }] = useAtom(languageAtom);
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin as any],
  });
  const unsubscribe = useRef<Unsubscribe>();

  function RTL(props: any) {
    return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
  }

  useLayoutEffect(() => {
    auth.onIdTokenChanged(() => {
      auth.onAuthStateChanged(async (user) => {
        if (!user) {
          setAuthInitialized(false);
        }
      });
    });

    auth.onAuthStateChanged(async (authData) => {
      if (authData !== null) {
        const roleDoc = await getDoc(doc(firestore, 'roles', authData.uid));

        if (roleDoc && roleDoc.exists()) {
          const roleData = roleDoc.data() as RoleData;

          if (roleData.fbId) {
            unsubscribe.current?.();
            unsubscribe.current = onSnapshot(doc(firestore, `${roleData.role}s`, roleData.fbId), async (fbUserData) => {
              const pics = [
                ...get(fbUserData.data(), 'logo', []),
                ...get(fbUserData.data(), 'photo', []),
              ] as UploadedFile[];

              setUserData({ ...fbUserData.data(), ...roleData, email: authData.email, pics });
              setAuthInitialized(true);
            });
          } else {
            setUserData({ ...roleData, email: authData.email });
            setAuthInitialized(true);
          }
        } else {
          const customerDoc = await getDoc(doc(firestore, 'customers', authData.uid));
          setUserData({ ...customerDoc.data() } as UserData);
          setAuthInitialized(true);
        }
      } else {
        setTimeout(() => {
          setAuthInitialized(true);
        }, 1000);
      }
    });
  }, [setAuthInitialized, setUserData]);

  useEffect(() => {
    return () => {
      unsubscribe.current?.();
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Redirect strict exact from='/' to='/login' />

        <Route exact path='/login' component={Login} />
        <Route exact path='/register' component={Register} />

        {rtlOn ? (
          <RTL>
            <AppContent />
          </RTL>
        ) : (
          <AppContent />
        )}
      </Switch>
      <ToastContainer position='bottom-center' />
    </BrowserRouter>
  );
};

export { App };
