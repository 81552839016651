import { collection, doc, documentId, getDoc, getDocs, Query, query, where, setDoc, deleteDoc } from '@firebase/firestore';
import { ImageNotSupported, LocalOfferOutlined } from '@mui/icons-material';
import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import { uniq, uniqBy } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Currency } from '../../components/common/ProfilePage/ProfilePage';
import { firestore } from '../../firebase';
import { useUserRole } from '../../hooks/useUserRole';
import { LangKeys, languageAtom } from '../../state/controls';
import { Customer, Offer } from '../Customers/Customers';
import { Project } from '../Projects/Projects';
import { Unit } from '../UnitProfile/UnitProfile';
import './Scans.scss';

const Scans: FC = () => {
  const [{ lang }] = useAtom(languageAtom);
  const { fbId } = useUserRole();
  const [customerUnits, setCustomerUnits] = useState<Unit[]>([]);
  const [customerOffers, setCustomerOffers] = useState<Offer[]>([]);
  const [relatedProjects, setRelatedProjects] = useState<Project[]>([]);
  const [relatedUnits, setRelatedUnits] = useState<Unit[]>([]);

  const fetchCustomerScans = useCallback(async () => {
    let projectIds: string[] = [];
    let unitIds: string[] = [];
    const customer = await (await getDoc(doc(firestore, 'customers', fbId))).data();

    const { units } = customer as Customer;

    const offersQuery = query(collection(firestore, 'offers'), where('customerId', '==', fbId));
    const offers = await (await getDocs(offersQuery)).docs;

    setCustomerOffers(offers.map((doc) => ({ ...doc.data(), id: doc.id } as Offer)));

    projectIds = [...projectIds, ...offers.map((offer) => offer.data().projectId)];

    const batches: Query[] = [];

    while (units?.length) {
      const batch = units.splice(0, 10);

      batches.push(query(collection(firestore, 'units'), where(documentId(), 'in', batch)));
    }

    if (batches?.length) {
      const unitBatches = await Promise.all(batches.map((query) => getDocs(query)));
      const units = uniqBy(
        unitBatches
          .map((batch) => batch.docs)
          .flat()
          .map((doc) => ({ ...doc.data(), id: doc.id } as Unit)),
        'id'
      );
      projectIds = [...projectIds, ...units.map((unit) => unit.projectId)];

      setCustomerUnits(units);
    }

    const projectBatches: Query[] = [];

    projectIds = uniq(projectIds);

    while (projectIds?.length) {
      const projectBatch = projectIds.splice(0, 10);

      projectBatches.push(query(collection(firestore, 'projects'), where(documentId(), 'in', projectBatch)));
    }

    if (projectBatches?.length) {
      const projectDocBatches = await Promise.all(projectBatches.map((query) => getDocs(query)));
      const projects = uniqBy(
        projectDocBatches
          .map((batch) => batch.docs)
          .flat()
          .map((doc) => ({ ...doc.data(), id: doc.id } as Project)),
        'id'
      );

      unitIds = projects.map((project) => project.units).flat() as string[];

      setRelatedProjects(projects);
    }

    const unitBatches: Query[] = [];

    unitIds = uniq(unitIds);

    while (unitIds?.length) {
      const unitBatch = unitIds.splice(0, 10);

      unitBatches.push(query(collection(firestore, 'units'), where(documentId(), 'in', unitBatch)));
    }

    if (unitBatches?.length) {
      const unitDocBatches = await Promise.all(unitBatches.map((query) => getDocs(query)));
      const units = uniqBy(
        unitDocBatches
          .map((batch) => batch.docs)
          .flat()
          .map((doc) => ({ ...doc.data(), id: doc.id } as Unit)),
        'id'
      );

      setRelatedUnits(units);
    }
  }, [fbId]);

  useEffect(() => {
    fetchCustomerScans();
  }, [fetchCustomerScans]);

  const onAcceptSubmit = async (id: any, isLoading: any) => {
    isLoading(true)
    await setDoc(doc(firestore, 'offers', `${id}`), { accepted: true }, { merge: true });
    isLoading(false)
  };

  const onDeclineSubmit = async (id: any, isLoading: any) => {
    isLoading(true)
    await deleteDoc(doc(firestore, 'offers', `${id}`));
    isLoading(false)
  };

  const renderOfferUnitCard = (unit: Unit, offerId: any) => {

    let [loading, setLoading] = useState<boolean | false>(false);

    return (
    <Stack>
      <Link to={`/units/${unit?.id}`} key={unit.id} style={{ textDecoration: 'none' }}>
        <Card elevation={3} className='card'>
          {unit?.pics?.length ? (
            <CardMedia component='img' sx={{ width: 150, height: 150 }} image={unit?.pics?.[0]?.url} />
          ) : (
            <div className='no-image'>
              <ImageNotSupported className='no-img-icon' />
            </div>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent>
              <Typography variant='subtitle1' color='text.secondary'>
                {lang[unit?.type as LangKeys]}
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                {lang.cost}: {unit?.priceOffer}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Link>
      <Stack direction='row' spacing={2}>
        <LoadingButton loading={loading} onClick={() => onAcceptSubmit(offerId, setLoading)}>Accept</LoadingButton >
        <LoadingButton  loading={loading} onClick={() => onDeclineSubmit(offerId, setLoading)} color='error'>Decline</LoadingButton >
      </Stack>
    </Stack>
  )
  
};

  const renderUnitCard = (unit: Unit) => (
    <Link to={`/units/${unit?.id}`} key={unit.id} style={{ textDecoration: 'none' }}>
      <Card elevation={3} className='card'>
        {unit?.pics?.length ? (
          <CardMedia component='img' sx={{ width: 150, height: 150 }} image={unit?.pics?.[0]?.url} />
        ) : (
          <div className='no-image'>
            <ImageNotSupported className='no-img-icon' />
          </div>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent>
            <Typography variant='subtitle1' color='text.secondary'>
              {lang[unit?.type as LangKeys]}
            </Typography>
            <Typography variant='subtitle1' color='text.secondary'>
              {lang.cost}: {unit?.priceOffer}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  );

  const renderUnitCards = () => {
    const units = customerUnits.filter((unit) => !customerOffers.find((offer) => offer.unitId === unit.id));

    return (
      <div className='cards'>
        <Typography variant='h6' color='text.secondary' sx={{ margin: '8px 0' }}>
          {lang.interested} {lang.units}
        </Typography>
        <div className="flex">
            {units?.length ? units.map(renderUnitCard) : lang.no_interested_units}
        </div>
      </div>
    );
  };

  const renderOfferCards = () => (
    <div className='cards'>
      <Typography variant='h6' color='text.secondary' sx={{ margin: '8px 0' }}>
        {lang.offers}
      </Typography>
      <div className="flex">
      {customerOffers?.length
        ? customerOffers.map((offer, ix) => {
            const offerUnit = customerUnits?.find((unit) => unit?.id === offer?.unitId);
            return (
              <Card elevation={3} key={ix} className='card'>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <LocalOfferOutlined />
                      <span>{lang.price_offer}:</span>
                      <span>{Currency[offer.currency as LangKeys]}</span>
                      <span>{offer.priceOffer}</span>
                    </Stack>
                  </Typography>
                  {offer.description && (
                    <Typography gutterBottom variant='subtitle1' component='div'>
                      <Stack direction='row' spacing={1}>
                        <span>{lang.description}:</span>
                        <span>{offer.description}</span>
                      </Stack>
                    </Typography>
                  )}

                  <div className='cards'>{offerUnit && renderOfferUnitCard(offerUnit, offer.id)}</div>
                </CardContent>
              </Card>
            );
          })
        : lang.no_offers}
        </div>
    </div>
  );

  const renderRelatedProjects = () => (
    <div className='cards'>
      <Typography variant='h6' color='text.secondary' sx={{ margin: '8px 0' }}>
        {lang.other_units}
      </Typography>
      {relatedProjects.map((project, ix) => (
        <Card elevation={3} key={ix} className='card'>
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              <Stack alignItems='center' direction='row' spacing={1}>
                <LocalOfferOutlined />
                <span>{lang.name}:</span>
                <span>{project.name}</span>
              </Stack>
              <div className='cards flex'>
                {relatedUnits
                  .filter((unit) => !customerOffers.find((offer) => offer.unitId === unit.id))
                  .filter((unit) => !customerUnits.find((customerUnit) => customerUnit.id === unit.id))
                  .filter((unit) => unit.projectId === project.id)
                  .map((unit) => renderUnitCard(unit))}
              </div>
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );

  return (
    <div className='scans'>
      {renderUnitCards()}
      {renderOfferCards()}
      {!!relatedProjects.length && renderRelatedProjects()}
    </div>
  );
};

export { Scans };
