import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { useAtom } from 'jotai';
import { languageAtom } from '../../../state/controls';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
export type UploadPayload = Record<string, FileWithPath[]>;

interface DropZoneProps {
  edit?: boolean;
  name: string;
  onLoad: (payload: UploadPayload) => void;
  multiple?: boolean;
}

const baseStyle: CSSProperties = {
  cursor: 'pointer',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '80px',
  marginBottom: '16px',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const UploadImageInput: FC<DropZoneProps> = ({ edit, name, onLoad, multiple = false }) => {
  const [{ lang }] = useAtom(languageAtom);
  const [myFiles, setMyFiles] = useState<FileWithPath[]>([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  const removeFile = (file: FileWithPath) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const style = useMemo<CSSProperties>(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = myFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={removeFile(file)}>
        <Close />
      </IconButton>
    </li>
  ));

  useEffect(() => {
    if (myFiles?.length) {
      onLoad({ [name]: myFiles });
    }
  }, [myFiles, name, onLoad]);

  return (
    <div className='container'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps({ multiple })} />
        {edit ? <p>{lang.edit_drag_n_drop}</p> : <p>{lang.drag_n_drop}</p>}
      </div>
      {!!files.length && (
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
          <button onClick={removeAll}>Remove All</button>
        </aside>
      )}
    </div>
  );
};

export { UploadImageInput };
