import { addDoc, collection, documentId, FieldValue, where } from '@firebase/firestore';
import { useAtom } from 'jotai';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { ElementCard } from '../../components/common/ElementCard/ElementCard';
import { FormModal } from '../../components/common/FormModal/FormModal';
import { ListPage } from '../../components/common/ListPage/ListPage';
import { firestore } from '../../firebase';
import { useUserRole } from '../../hooks/useUserRole';
import { languageAtom } from '../../state/controls';
import { Developer, developerFormItems } from '../DeveloperProfile/DeveloperProfile';
import './Developers.scss';

const Developers: FC = () => {
  const [addDeveloperModalOpen, setAddDeveloperModalOpen] = useState(false);
  const [{ lang }] = useAtom(languageAtom);
  const { isAdmin, isDeveloper, uid } = useUserRole();

  const developerContentConfig = (developer: Developer) => [
    { label: lang.name, value: developer.name },
    { label: lang.phone, value: developer.phone },
  ];

  const developerFooterConfig = (developer: Developer) => [
    {
      label: lang.description,
      value: developer.description,
    },
  ];

  const onSubmit = async (data: Record<string, FieldValue>) => {
    const newDoc = await addDoc(collection(firestore, 'developers'), data);

    await addDoc(collection(firestore, 'roles'), {
      role: 'developer',
      phone: data.phone,
      fbId: newDoc.id,
    });

    toast.success('Developer Profile Created');
    setAddDeveloperModalOpen(false);
    return Promise.resolve();
  };

  return (
    <div className='developers'>
      <ListPage
        actionText={lang.new_developer}
        onActionBtnClick={() => setAddDeveloperModalOpen(true)}
        actionAllowed={isAdmin}
        collectionName='developers'
        queryConstraint={isDeveloper ? where(documentId(), '==', uid) : undefined}
        renderElement={(element, highlight: string) => {
          const developer = element as unknown as Developer;
          return (
            <ElementCard
              small
              element={{
                linkPath: 'developers',
                id: developer.id,
                name: developer.name,
                pics: developer.logo,
                content: developerContentConfig(developer),
                footer: developerFooterConfig(developer),
              }}
              highlight={highlight}
            />
          );
        }}
      />

      <FormModal
        collectionName='developers'
        title={lang.developer_form}
        formItems={developerFormItems(lang)}
        isOpen={addDeveloperModalOpen}
        onClose={() => setAddDeveloperModalOpen(false)}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export { Developers };
