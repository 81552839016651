import { Close } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import React, { FC } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { UploadedFile } from '../FormModal/FormModal';
import './GalleryModal.scss';

interface GalleryModalProps {
  isOpen: boolean;
  images: UploadedFile[];
  onClose: () => void;
}

const GalleryModal: FC<GalleryModalProps> = ({ isOpen, images, onClose }) => {
  return (
    <Modal className='gallery-modal' open={isOpen} onClose={onClose}>
      <div>
        <ReactImageGallery
          additionalClass='profile-gallery'
          renderCustomControls={() => (
            <IconButton className='close-btn' onClick={onClose}>
              <Close fontSize='large' />
            </IconButton>
          )}
          items={
            images.map(({ url }) => ({
              original: url,
              thumbnail: url,
            })) || []
          }
        />
      </div>
    </Modal>
  );
};

export { GalleryModal };
