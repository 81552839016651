import React, { FC, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import Highlight from 'react-highlighter';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { languageAtom } from '../../../state/controls';
import './ElementCard.scss';
import { UploadedFile } from '../FormModal/FormModal';

interface ContentValue {
    value: string | number;
    label: string;
    additionalLabel?: string;
}

export interface ElementConfig {
    id: string;
    name?: string;
    linkPath: string;
    pics?: UploadedFile[];
    content?: ContentValue[];
    footer?: ContentValue[];
}

export interface ElementProps {
    small?: boolean;
    element: ElementConfig;
    highlight?: string;
    className?: string;
}

const ElementCard: FC<ElementProps> = ({ element, highlight = '', small, className }) => {
    const [{ lang }] = useAtom(languageAtom);
    const [imageLoaded, setImageLoaded] = useState(false);

    const renderStack = (values?: ContentValue[], direction?: 'column') => (
        <Stack direction={direction || 'row'} spacing={1}>
            {values?.map(({ value, label, additionalLabel }, ix) => (
                <Stack key={ix} direction='row' spacing={1}>
                    {label && (
                        <Stack direction='column'>
                            <span className='label'>{label} :</span>
                        </Stack>
                    )}
                    <span>
                        <Highlight search={highlight}>{additionalLabel}</Highlight>
                    </span>
                    <span className='card-value'>
                        <Highlight search={highlight}>{value}</Highlight>
                    </span>
                </Stack>
            ))}
        </Stack>
    );

    const renderCardContent = (direction?: 'column') => (
        <>
            {renderStack(element.content, direction)}
            {element.footer && (
                <Typography variant='subtitle2' color='text.secondary'>
                    {renderStack(element.footer, direction)}
                </Typography>
            )}
        </>
    );

    const renderSmallCard = () => (
        <Card className='element-card small'>
            {element.pics?.[0] ? (
                <CardMedia component='img' className='image' image={element.pics[0].url} />
            ) : (
                <Paper className='no-media'>{lang.no_images}</Paper>
            )}
            <CardContent className='content'>{renderCardContent('column')}</CardContent>

        </Card>
    );

    const renderBigCard = () => (
        <Card elevation={3} id={element.id} className='element-card'>
            <CardHeader title={<Highlight search={highlight}>{element.name}</Highlight>} />

            {element.pics?.length ? (
                <>
                    {!imageLoaded && (
                        <div className='image-loading'>
                            <CircularProgress />
                        </div>
                    )}
                    <CardMedia
                        className={classNames({ loading: !imageLoaded })}
                        component='img'
                        height='194'
                        image={element.pics[0].url}
                        onLoad={() => setImageLoaded(true)}
                    />
                </>
            ) : (
                <Paper className='no-media'>{lang.no_images}</Paper>
            )}

            <CardContent>{renderCardContent()}</CardContent>
        </Card>
    );

    return (
        <NavLink className={className} style={{ textDecoration: 'none' }} to={`/${element.linkPath}/${element.id}`}>
            {small ? renderSmallCard() : renderBigCard()}
        </NavLink>
    );
};

export { ElementCard };
