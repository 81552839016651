import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { languageAtom } from '../../../state/controls';

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onYes: () => void;
  title: string;
  loadingText?: string;
  loading?: boolean;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({ isOpen, onClose, onYes, title, loading, loadingText }) => {
  const [{ lang }] = useAtom(languageAtom);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {loading && (
        <DialogContent className='dialog-content'>
          <Stack direction='row' spacing={2}>
            <div>
              <CircularProgress />
            </div>
            {loadingText}
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          {lang.no}
        </Button>
        <Button disabled={loading} onClick={onYes} autoFocus>
          {lang.yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog };
