import { atom } from 'jotai';
import { lang } from '../i18n';

export type Lang = typeof lang.en | typeof lang.arab;
export type LangKeys = keyof Lang;
export type LangValues = Lang[LangKeys];

type CurrentLang = 'ar' | 'eng';

export interface LangConfig {
  current: CurrentLang;
  lang: Lang;
  rtlOn: boolean;
}

const defaultState = localStorage.getItem('wareef-lang') as CurrentLang;

const languageAtom = atom<LangConfig>({
  current: defaultState || 'ar',
  lang: defaultState === 'ar' ? lang.arab : lang.en,
  rtlOn: defaultState === 'ar',
});

const switchLanguageAtom = atom(null, (get, set) => {
  const { current } = get(languageAtom);
  const isArab = current === 'ar';
  const newCurrent = isArab ? 'eng' : 'ar';
  const newLang = isArab ? lang.en : lang.arab;

  localStorage.setItem('wareef-lang', newCurrent);

  set(languageAtom, { current: newCurrent, lang: newLang, rtlOn: !isArab });
});

export { languageAtom, switchLanguageAtom };
