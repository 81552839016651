import {
  FieldItem,
  FormItem,
} from "../../components/common/FormModal/FormModal";
import { Lang } from "../../state/controls";

export const projectFormItems = (
  lang: Lang,
  developerItems: FieldItem[],
  defaultDeveloper?: FieldItem
): FormItem[] => [
  {
    name: "developerId",
    label: lang.developer,
    type: "autocomplete",
    required: true,
    items: developerItems,
    defaultValue: defaultDeveloper,
  },
  {
    name: "name",
    label: lang.project_name,
    type: "text",
    required: true,
  },
  {
    name: "currency",
    label: lang.currency,
    type: "select",
    defaultValue: "SAR",
    items: [
      {
        value: "SAR",
        label: "SAR",
      },
      {
        value: "USD",
        label: "USD",
      },
      {
        value: "EUR",
        label: "EUR",
      },
    ],
    required: true,
  },
  {
    name: "cost",
    label: lang.cost,
    type: "floatNumber",
    required: true,
  },
  {
    name: "address",
    label: lang.address,
    type: "text",
    required: true,
  },
  {
    name: "video",
    label: lang.video,
    type: "url",
    required: true,
  },
  {
    name: "location",
    label: "",
    type: "gps",
    required: true,
    xs: 12,
    md: 12,
  },
  {
    name: "reservation",
    label: lang.max_reservation,
    type: "number",
    required: true,
    md: 8.4,
    xs: 12,
  },
  {
    name: "services",
    label: lang.available_services_nearby,
    type: "checkbox",
    checkBoxes: [
      {
        name: "infrastructure",
        label: lang.infrastructure,
      },
      {
        name: "electricity_cables",
        label: lang.electricity_cables,
      },
      {
        name: "street",
        label: lang.street,
      },
      {
        name: "phone",
        label: lang.phone,
      },

      {
        name: "solar_energy",
        label: lang.solar_energy,
      },
    ],
    required: true,
  },

  {
    name: "more",
    label: lang.more,
    type: "text",
    required: false,
  },
  {
    name: "city_en",
    label: lang.city_en,
    type: "text",
    required: true,
  },
  {
    name: "city_ar",
    label: lang.city_ar,
    type: "text",
    required: true,
  },
  {
    name: "description_en",
    label: lang.description_en,
    type: "text",
    required: true,
  },
  {
    name: "description_ar",
    label: lang.description_ar,
    type: "text",
    required: true,
  },
  {
    name: "warranties_en",
    label: lang.warranties_en,
    type: "text",
    required: true,
  },
  {
    name: "warranties_ar",
    label: lang.warranties_ar,
    type: "text",
    required: true,
  },
  {
    name: "electricity",
    label: lang.electricity,
    type: "number",
    required: true,
  },
  {
    name: "concrete",
    label: lang.concrete,
    type: "number",
    required: true,
  },
  {
    name: "conditioning",
    label: lang.conditioning,
    type: "number",
    required: true,
  },
  {
    name: "reservoirs",
    label: lang.reservoirs,
    type: "number",
    required: true,
  },
  {
    name: "paints",
    label: lang.paints,
    type: "number",
    required: true,
  },
  {
    name: "inoutdoor",
    label: lang.inoutdoor,
    type: "number",
    required: true,
  },
  {
    name: "sanitary",
    label: lang.sanitary,
    type: "number",
    required: true,
  },
  {
    name: "isolation",
    label: lang.isolation,
    type: "number",
    required: true,
  },
  {
    name: "plumbing",
    label: lang.plumping,
    type: "number",
    required: true,
  },
  {
    name: "floors",
    label: lang.floors_x,
    type: "number",
    required: true,
  },
  /*
  {
    name: "more1",
    label: lang.more,
    type: "text",
    required: true,
  },
  {
    name: "more1_number",
    label: lang.warranties,
    type: "number",
    required: true,
  },
  {
    name: "more2",
    label: lang.more,
    type: "text",
    required: true,
  },
  {
    name: "more2_number",
    label: lang.warranties,
    type: "number",
    required: true,
  },
  {
    name: "more3",
    label: lang.more,
    type: "text",
    required: true,
  },
  {
    name: "more3_number",
    label: lang.warranties,
    type: "number",
    required: true,
  },
  */
  {
    name: "floors",
    label: lang.floors_x,
    type: "number",
    required: true,
  },
  {
    name: "fromTo",
    label: "",
    type: "dateRange",
    required: true,
    xs: 12,
    md: 12,
  },
  {
    name: "facilities_en",
    label: lang.facilities_en,
    type: "text",
    required: true,
  },
  {
    name: "facilities_ar",
    label: lang.facilities_ar,
    type: "text",
    required: true,
  },
  {
    name: "logo",
    label: lang.project_logo,
    type: "image",
    required: true,
    xs: 12,
    md: 12,
  },
  {
    name: "pics",
    label: lang.project_images,
    type: "image",
    multiple: true,
    required: true,
    xs: 12,
    md: 12,
  },
];

export const unitFormItems = (
  lang: Lang,
  parentCurrency?: string
): FormItem[] => [
  {
    name: "adStatus",
    label: lang.adStatus,
    type: "select",
    defaultValue: "Advertiser",
    items: [
      {
        value: "Advertiser",
        label: lang.Advertiser,
      },
      {
        value: "Authorized",
        label: lang.Authorized,
      },
    ],
    required: true,
  },
  {
    name: "idType",
    label: lang.idType,
    type: "select",
    defaultValue: "Citizen",
    items: [
      {
        value: "Citizen",
        label: lang.Citizen,
      },

      {
        value: "Resident",
        label: lang.Resident,
      },
      {
        value: "Facility",
        label: lang.facility,
      },
    ],
    required: true,
  },
  {
    name: "idNumber",
    label: lang.idNumber,
    type: "text",
    required: true,
  },
  {
    name: "authNumber",
    label: lang.authNumber,
    type: "text",
    required: false,
  },
  {
    name: "adNumber",
    label: lang.adNumber,
    type: "text",
    required: true,
  },
  {
    name: "type",
    label: lang.property_type,
    type: "select",
    defaultValue: "apartment",
    items: [
      {
        value: "apartment",
        label: lang.apartment,
      },
      {
        value: "villa",
        label: lang.villa,
      },
    ],
    required: true,
  },
  {
    name: "units",
    label: lang.units,
    type: "text",
    required: true,
  },
  {
    name: "status",
    label: lang.status,
    type: "select",
    defaultValue: "Available",
    items: [
      {
        value: "Available",
        label: lang.available,
      },
      {
        value: "Booked",
        label: lang.booked,
      },
      {
        value: "Sold",
        label: lang.sold,
      },
    ],
    required: true,
  },
  {
    name: "floor",
    label: lang.floor,
    type: "number",
    required: true,
  },
  {
    name: "number",
    label: lang.number,
    type: "number",
    required: true,
  },
  {
    name: "cost",
    label: lang.cost,
    additionalLabel: parentCurrency,
    type: "floatNumber",
    required: true,
  },
  {
    name: "size",
    label: lang.size,
    additionalLabel: lang.sqm,
    type: "floatNumber",
    required: true,
  },
  {
    name: "buildArea",
    label: lang.build_area,
    additionalLabel: lang.sqm,
    type: "floatNumber",
    required: true,
  },
  {
    name: "bedroom",
    label: lang.bedroom,
    type: "number",
    required: true,
  },
  {
    name: "bathrooms",
    label: lang.bathrooms,
    type: "number",
    required: true,
  },
  {
    name: "facilities",
    label: lang.facilities_en,
    type: "text",
    required: true,
  },
  {
    name: "facilities",
    label: lang.facilities_ar,
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: lang.description_en,
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: lang.description_ar,
    type: "text",
    required: true,
  },
  {
    name: "priceOffer",
    label: lang.price_offer,
    additionalLabel: parentCurrency,
    type: "floatNumber",
    required: true,
  },
  {
    name: "plane",
    label: lang.unit_plane,
    type: "image",
    required: true,
    xs: 12,
    md: 12,
  },
  {
    name: "pics",
    label: lang.unit_images,
    type: "image",
    required: true,
    xs: 12,
    md: 12,
  },
];
