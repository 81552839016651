import { useAtom } from 'jotai';
import React, { FC, useState } from 'react';
import { Lang, languageAtom } from '../../state/controls';
import { Realtor } from '../RealtorProfile/RealtorProfile';
import { ListPage } from '../../components/common/ListPage/ListPage';
import { ElementCard } from '../../components/common/ElementCard/ElementCard';
import { FormItem, FormModal } from '../../components/common/FormModal/FormModal';
import { addDoc, collection, FieldValue, where } from '@firebase/firestore';
import { firestore } from '../../firebase';
import { toast } from 'react-toastify';
import { useUserRole } from '../../hooks/useUserRole';

export const realtorFormItems = (lang: Lang): FormItem[] => [
  {
    name: 'name',
    label: lang.name,
    type: 'text',
    required: true,
  },
  {
    name: 'phone',
    label: lang.phone,
    type: 'phone',
    required: true,
  },
  {
    name: 'website',
    label: lang.website,
    type: 'url',
    required: true,
  },
  {
    name: 'description',
    label: lang.description,
    type: 'text',
    required: true,
  },
  {
    name: 'type',
    label: lang.type,
    type: 'select',
    defaultValue: 'contractor',
    items: [
      {
        value: 'contractor',
        label: lang.contractor,
      },
      {
        value: 'freelancer',
        label: lang.freelancer,
      },
    ],
    required: true,
  },
  {
    name: 'photo',
    label: lang.photo,
    type: 'image',
    required: true,
  },
];

const Realtors: FC = () => {
  const [addRealtorModalOpen, setAddRealtorModalOpen] = useState(false);
  const [{ lang }] = useAtom(languageAtom);
  const { isAdmin, isDeveloper, fbId } = useUserRole();

  const realtorContentConfig = (realtor: Realtor) => [
    { label: lang.name, value: realtor.name },
    { label: lang.phone, value: realtor.phone },
  ];

  const realtorFooterConfig = (realtor: Realtor) => [
    {
      label: lang.description,
      value: realtor.description,
    },
  ];

  const onSubmit = async (data: Record<string, FieldValue>) => {
    const newDoc = await addDoc(collection(firestore, 'realtors'), { ...data, developerId: fbId });

    await addDoc(collection(firestore, 'roles'), {
      role: 'realtor',
      phone: data.phone,
      fbId: newDoc.id,
    });

    toast.success('Realtor Profile Created');
    setAddRealtorModalOpen(false);
    return Promise.resolve();
  };

  return (
    <div style={{ height: '100%' }}>
      <ListPage
        actionText={lang.new_realtor}
        onActionBtnClick={() => setAddRealtorModalOpen(true)}
        actionAllowed={isAdmin || isDeveloper}
        collectionName='realtors'
        queryConstraint={isDeveloper ? where('developerId', '==', fbId) : undefined}
        renderElement={(element, highlight: string) => {
          const realtor = element as unknown as Realtor;
          return (
            <ElementCard
              small
              element={{
                linkPath: 'realtors',
                id: realtor.id,
                name: realtor.name,
                pics: realtor.photo,
                content: realtorContentConfig(realtor),
                footer: realtorFooterConfig(realtor),
              }}
              highlight={highlight}
            />
          );
        }}
      />

      <FormModal
        collectionName='realtors'
        title={lang.realtor_form}
        formItems={realtorFormItems(lang)}
        isOpen={addRealtorModalOpen}
        onClose={() => setAddRealtorModalOpen(false)}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export { Realtors };
