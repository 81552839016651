import { Close, Delete } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    GridSize,
    IconButton,
    ImageList,
    ImageListItem,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import {
    findIndex,
    isEmpty,
    isEqual,
    isNil,
    pullAt,
    snakeCase,
    uniqueId,
} from "lodash";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import React, {
    FC,
    HTMLInputTypeAttribute,
    useEffect,
    useRef,
    useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { languageAtom, LangValues } from "../../../state/controls";
import ReactPhoneInput from "react-phone-input-mui";

import {
    UploadImageInput,
    UploadPayload,
} from "../UploadImageInput/UploadImageInput";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytes,
} from "@firebase/storage";
import { firestore, storage } from "../../../firebase";
import { toast } from "react-toastify";
import {
    doc,
    FieldValue,
    setDoc,
    Timestamp,
    updateDoc,
} from "@firebase/firestore";
import { FirebaseError } from "@firebase/util";
import NumberFormat from "react-number-format";
import { LocalizationProvider, MobileDateRangePicker } from "@mui/lab";
import { Box } from "@mui/system";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import "./FormModal.scss";

export interface UploadedFile {
    name: string;
    url: string;
}

export const deleteImagesFromStorage = async (
    collection: string,
    docId: string,
    fieldName: string,
    images: UploadedFile[]
): Promise<void> => {
    const docRef = doc(firestore, collection, docId);

    await updateDoc(docRef, {
        [fieldName]: [],
    });

    images?.length &&
        (await Promise.all(
            images.map(async ({ name }) => {
                const imagRef = ref(storage, name);
                return deleteObject(imagRef);
            })
        ));

    return Promise.resolve();
};

export const formatDateField = (values: Timestamp[]): string => {
    return values
        .map(({ seconds }) => new Date(seconds * 1000).toLocaleDateString())
        .join(" ~ ");
};

export interface FieldItem {
    value: string;
    label: LangValues;
}

export interface CheckboxFieldItem {
    name: string;
    label: LangValues;
}

export interface FormItem {
    name: string;
    label: string;
    additionalLabel?: string;
    type: HTMLInputTypeAttribute;
    multiple?: boolean;
    defaultValue?: string | FieldItem | FieldItem[];
    items?: FieldItem[];
    checkBoxes?: CheckboxFieldItem[];
    xs?: number;
    md?: number;
    required?: boolean;
}

interface MultiConfig {
    incrementField: string;
    onValueChange: (from: number, to: number) => void;
}

interface FormModalProps {
    collectionName: string;
    title: LangValues;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: Record<string, FieldValue>) => Promise<void>;
    formItems: FormItem[];
    multiConfig?: MultiConfig;
    defaultValues?: unknown & { id: string };
    defaultFiles?: Record<string, UploadedFile[] | undefined>;
}

const FormModal: FC<FormModalProps> = ({
    collectionName,
    title,
    isOpen,
    onClose,
    onSubmit,
    defaultValues,
    formItems,
    multiConfig,
    defaultFiles,
}) => {
    const [{ lang, rtlOn }] = useAtom(languageAtom);
    const { handleSubmit, register, control, reset, getValues, watch } = useForm<
        Record<string, unknown>
    >({
        mode: "onBlur",
    });
    const [files, setFiles] = useState<UploadPayload>({});
    const [isFetching, setIsFetching] = useState(false);
    const [isMultiChecked, setIsMultiChecked] = useState(false);
    const [multiLimit, setMultiLimit] = useState(0);
    const [logoImages, setLogoImages] = useState<any>(defaultFiles ? defaultFiles["logo"] : []);
    const [picsImages, setPicsImages] = useState<any>(defaultFiles ? defaultFiles["pics"] : []);
    const [planeImages, setPlaneImages] = useState<any>(defaultFiles ? defaultFiles["plane"] : []);

    useEffect(() => {
        setLogoImages(defaultFiles ? defaultFiles["logo"] : [])
        setPicsImages(defaultFiles ? defaultFiles["pics"] : [])
        setPlaneImages(defaultFiles ? defaultFiles["plane"] : [])
    }, [defaultFiles])

    const uploadedFiles = useRef<Record<string, UploadedFile[]>>({});

    useEffect(() => {
        reset(defaultValues || {});
    }, [defaultValues, reset]);

    const onFilesLoaded = (payload: UploadPayload) => {
        const newState = { ...files, ...payload };
        if (!isEqual(newState, files)) {
            setFiles(newState);
        }
    };

    const handleClose = () => {
        setIsMultiChecked(false);
        reset();
        onClose();
    };

    const handleFiles = async () => {
        if (!isEmpty(files)) {
            await Promise.all(
                Object.keys(files).map(async (key) => {
                    const fileList = files[key as keyof UploadPayload];

                    const namedFileList: string[] = [];

                    await Promise.all(
                        fileList.map((file) => {
                            const fileName = uniqueId(
                                `${snakeCase(key)}_${new Date().getTime()}`
                            );
                            const storageRef = ref(storage, fileName);
                            namedFileList.push(fileName);

                            return uploadBytes(storageRef, file).catch((error) => {
                                toast.error(
                                    `Something went wrong while uploading your image, ${error.message || error
                                    }`
                                );
                            });
                        })
                    );

                    const uploaded = await Promise.all(
                        namedFileList.map(async (name) => {
                            const url = await getDownloadURL(ref(storage, name));

                            return Promise.resolve({ name, url });
                        })
                    );

                    uploadedFiles.current = {
                        ...uploadedFiles.current,
                        [key]: [...[...(defaultFiles?.[key] || [])], ...uploaded],
                    };
                    return Promise.resolve();
                })
            );
        }
    };

    const handleImages = async () => {
        const currentDocument = defaultValues as Record<string, unknown> & {
            id: string;
        };

        const logos = logoImages;
        const pics = picsImages;
        const planes = planeImages

        if (collectionName === "projects") {
            await updateDoc(
                doc(firestore, collectionName, currentDocument.id),
                { logo: logos, pics: pics }
            )
        }
        else if (collectionName === "units") {
            await updateDoc(
                doc(firestore, collectionName, currentDocument.id),
                { plane: planes, pics: pics }
            )
        }
    }

    const formSubmit = handleSubmit(async (data: Record<string, unknown>) => {
        setIsFetching(true);
        try {

            await handleFiles();
            await onSubmit({ ...data, ...uploadedFiles.current } as Record<
                string,
                FieldValue
            >);
            if ((logoImages?.length > 1 && picsImages?.length > 1) || (planeImages?.length > 1 && picsImages?.length > 1)) {
                await handleImages()
            }
        } catch (error) {
            const e = error as FirebaseError;
            toast.error(e.message || e);
        }

        setIsFetching(false);
    });

    const removeImages = async (name: string, images: UploadedFile[]) => {
        setIsFetching(true);
        const { [name]: omit, ...rest } = uploadedFiles.current;
        uploadedFiles.current = rest;

        await deleteImagesFromStorage(
            collectionName,
            defaultValues?.id as string,
            name,
            images
        );

        setIsFetching(false);
        toast.success("Profile info saved");
    };

    const removeImage = async (name: string, value: UploadedFile) => {
        setIsFetching(true);

        const currentDocument = defaultValues as Record<string, unknown> & {
            id: string;
        };

        const imageIndex = findIndex(
            defaultFiles?.[name],
            (file) => file.name === value.name
        );
        const newImageList = [...(defaultFiles?.[name] || [])];

        pullAt(newImageList, imageIndex);

        await setDoc(
            doc(firestore, collectionName, currentDocument.id),
            { [name]: newImageList },
            { merge: true }
        );

        const imagRef = ref(storage, value.name);
        await deleteObject(imagRef);

        setIsFetching(false);
        toast.success("Profile info saved");
    };

    const RenderImagesEdit: FC<any> = ({ name }: any) => {

        const SortableItem = SortableElement(({ value }: any) => (

            <div style={{ margin: "10px", width: "160px", zIndex: 1300 }}>
                <div style={{ position: "relative" }}>
                    <IconButton
                        style={{ position: "absolute", right: 0 }}
                        disabled={isFetching}
                        size='small'

                        onClick={() => removeImage(name, value)}
                    >
                        <Close />
                    </IconButton>
                </div>
                <img height={'160px'} width={'160px'} src={value.url} />
            </div>
        )
        );

        const SortableGallery = SortableContainer(({ children }: any) => {
            return <ul>{children}</ul>;
        });

        const onSortEnd = ({ oldIndex, newIndex }: any) => {
            console.log(name)
            if (name === "logo") {
                let arr = arrayMoveImmutable(logoImages, oldIndex, newIndex)
                setLogoImages(arr);
            } else if (name === "pics") {
                let arr = arrayMoveImmutable(picsImages, oldIndex, newIndex)
                setPicsImages(arr);
            } else if (name === "plane") {
                let arr = arrayMoveImmutable(planeImages, oldIndex, newIndex)
                setPlaneImages(arr);
            }
        };

        if (name === "logo") {
            return (
                <SortableGallery onSortEnd={onSortEnd}>
                    {
                        logoImages && logoImages.map((val: any, index: any) => {
                            return <SortableItem key={`item-${index}`} index={index} value={val} />
                        })
                    }
                </SortableGallery>
            );
        } else if (name === "pics") {
            return (
                <SortableGallery onSortEnd={onSortEnd} distance={1}>
                    {
                        picsImages && picsImages.map((val: any, index: any) => {
                            return <SortableItem key={`item-${val}`} index={index} value={val} />
                        })
                    }
                </SortableGallery>
            );
        } else {
            return (
                <SortableGallery onSortEnd={onSortEnd} distance={1}>
                    {
                        planeImages && planeImages.map((val: any, index: any) => {
                            return <SortableItem key={`item-${val}`} index={index} value={val} />
                        })
                    }
                </SortableGallery>
            );
        }

    };

    const renderItem = ({
        type,
        name,
        required,
        label: defaultLabel,
        additionalLabel,
        defaultValue,
        items,
        checkBoxes,
        multiple,
    }: FormItem) => {
        const label = additionalLabel
            ? `${defaultLabel} (${additionalLabel})`
            : defaultLabel;
        switch (type) {
            case "text":
            case "url":
            case "number":
                return (
                    <TextField
                        {...register(name)}
                        fullWidth
                        required={required}
                        type={type}
                        label={label}

                    />
                );

            case "select":
                return (
                    <Select
                        fullWidth
                        className="select"
                        defaultValue={defaultValue}
                        {...register(name)}
                        label={label}
                    >
                        {items?.map(({ value, label }, ix) => (
                            <MenuItem key={ix} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                );
            case "checkbox":
                return (
                    <FormGroup>
                        <div className="label"> {label} </div>
                        {checkBoxes?.map((item: CheckboxFieldItem) => (
                            <>
                                <Controller
                                    control={control}
                                    name={item.name}
                                    render={({ field: { value, onChange } }) => {
                                        if (value === undefined) {
                                            onChange(false)
                                        }
                                        return (
                                            <>
                                                <FormControlLabel
                                                    checked={value as boolean}
                                                    onChange={(eve) => {
                                                        onChange(!value)
                                                    }}
                                                    control={<Checkbox />}
                                                    label={item.label} />
                                            </>
                                        )
                                    }}
                                />

                            </>
                        ))}
                    </FormGroup>
                );
            case "Radio":
                return (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">حالة المعلن</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="معلن" control={<Radio />} label="معلن" />
                                        <FormControlLabel value="مفوض" control={<Radio  />} label="مفوض" />
                                    </RadioGroup>
                                </FormControl>
                            );
                        }}
                    />
                );
            case "phone":
                return (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <ReactPhoneInput
                                    defaultCountry="sa"
                                    component={TextField}
                                    value={value}
                                    onChange={onChange}
                                    inputExtraProps={{
                                        required,
                                        label,
                                    }}
                                />
                            );
                        }}
                    />
                );
            case "floatNumber":
                return (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { value, onChange } }) => {
                            return (

                                <NumberFormat
                                    isNumericString={true}
                                    thousandSeparator={true}
                                    decimalScale={5}
                                    required={required}
                                    value={(value as string)}
                                    customInput={TextField}
                                    onChange={onChange}
                                    variant="outlined"
                                    label={label}
                                />
                            );
                        }}
                    />
                );

            case "image":
                return (
                    <>
                        <div className="label">
                            {label}
                            {!!defaultFiles?.[name]?.length && (
                                <Button
                                    sx={{ margin: "0 8px" }}
                                    variant="contained"
                                    disabled={isFetching}
                                    startIcon={<Delete />}
                                    onClick={() =>
                                        removeImages(name, defaultFiles[name] as UploadedFile[])
                                    }
                                >
                                    {!isFetching ? (
                                        "Clear"
                                    ) : (
                                        <CircularProgress color="info" size={24} />
                                    )}
                                </Button>
                            )}
                        </div>

                        {defaultFiles?.[name]?.length ? (
                            <>
                                <RenderImagesEdit name={name} />
                                {multiple && (
                                    <div className="edit-pics">
                                        <UploadImageInput
                                            edit
                                            name={name}
                                            multiple
                                            onLoad={onFilesLoaded}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <UploadImageInput name={name} multiple onLoad={onFilesLoaded} />
                        )}
                    </>
                );

            case "gps":
                return (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { value, onChange } }) => (
                            <Stack direction="row" spacing={2}>
                                <NumberFormat
                                    onValueChange={(v) => {
                                        const newValue = `${v.formattedValue}, ${(value as string)?.split(",")[1]
                                            }`;
                                        onChange(newValue);
                                    }}
                                    value={(value as string)?.split(",")[0]}
                                    format="##.######"
                                    mask="_"
                                    customInput={TextField}
                                    required={required}
                                    variant="outlined"
                                    label={lang.latitude}
                                />
                                <NumberFormat
                                    onValueChange={(v) => {
                                        const newValue = `${(value as string)?.split(",")[0]}, ${v.formattedValue
                                            }`;
                                        onChange(newValue);
                                    }}
                                    value={(value as string)?.split(",")[1]}
                                    format="##.######"
                                    mask="_"
                                    customInput={TextField}
                                    required={required}
                                    variant="outlined"
                                    label={lang.longitude}
                                />
                            </Stack>
                        )}
                    />
                );

            case "dateRange":
                return (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { value, onChange } }) => {
                            const parsedDate = (value as Timestamp[])?.[0].seconds
                                ? (value as Timestamp[]).map(
                                    ({ seconds }) => new Date(seconds * 1000)
                                )
                                : value;
                            return (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack direction="row" spacing={3}>
                                        <MobileDateRangePicker
                                            startText={lang.from}
                                            value={(parsedDate || [null, null]) as DateRange<Date>}
                                            onChange={onChange}
                                            renderInput={(startProps, endProps) => {
                                                return (
                                                    <>
                                                        <TextField required={required} {...startProps} />
                                                        <Box sx={{ mx: 2 }}> {lang.to} </Box>
                                                        <TextField
                                                            required={required}
                                                            {...endProps}
                                                            label={lang.end}
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            );
                        }}
                    />
                );

            case "autocomplete":
                return (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                multiple={multiple}
                                isOptionEqualToValue={(option, value) => isEqual(option, value)}
                                disablePortal
                                defaultValue={defaultValue as unknown as FieldItem}
                                options={items as FieldItem[]}
                                onChange={(event, newValue) => {
                                    onChange(
                                        multiple
                                            ? (newValue as FieldItem[])?.map(({ value }) => value)
                                            : (newValue as FieldItem)?.value
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required={required}
                                        name={name}
                                        type="text"
                                        label={label}
                                        inputProps={{
                                            ...params.inputProps,
                                            required: (value as any)?.length === 0 || isNil(value),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                );

            default:
                break;
        }
    };

    multiConfig && watch(multiConfig.incrementField);

    const renderMultiSection = () => {
        const { incrementField, onValueChange } = multiConfig as MultiConfig;
        const incrementFieldValue = getValues(incrementField) as number;

        return (
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center">
                    {lang.create_multi}

                    <Checkbox
                        checked={isMultiChecked}
                        onChange={(event) => {
                            const checked = event.target.checked;

                            if (!checked) {
                                setMultiLimit(0);
                                onValueChange(0, 0);
                            }
                            setIsMultiChecked(checked);
                        }}
                    />

                    {isMultiChecked && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <span>
                                {lang.from}{" "}
                                {formItems.find((item) => item.name === incrementField)?.label}
                            </span>

                            <span>{incrementFieldValue}</span>

                            <TextField
                                type="number"
                                label={lang.to}
                                value={multiLimit}
                                inputProps={{
                                    min: incrementFieldValue
                                        ? Number(incrementFieldValue) + 1
                                        : 0,
                                }}
                                disabled={`${incrementFieldValue}` === ""}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    const newValue = Number(event.target.value);

                                    if (Number(value) <= incrementFieldValue) {
                                        setMultiLimit(Number(incrementFieldValue) + 1);
                                    } else {
                                        setMultiLimit(newValue);
                                    }

                                    onValueChange(Number(incrementFieldValue), newValue);
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
            </Grid>
        );
    };

    return (
        <Modal
            dir={rtlOn ? "rtl" : "ltr"}
            className="form-modal"
            open={isOpen}
            onClose={handleClose}
        >
            <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
                <Paper className="modal-content">
                    <form onSubmit={formSubmit}>
                        <div className="header">
                            <IconButton className="close-btn" onClick={handleClose}>
                                <Close />
                            </IconButton>
                            <Typography variant="h4">{title}</Typography>
                            <Grid container className="form-actions">
                                <Grid item className="form-btn">
                                    <Button
                                        disabled={isFetching}
                                        type="button"
                                        variant="contained"
                                        onClick={() => reset(defaultValues || {})}
                                    >
                                        {lang.reset}
                                    </Button>
                                </Grid>
                                <Grid item className="form-btn">
                                    <Button
                                        disabled={isFetching}
                                        color="secondary"
                                        type="submit"
                                        variant="contained"
                                        className="btn"
                                    >
                                        {!isFetching ? (
                                            !isEmpty(defaultValues) ? (
                                                lang.save
                                            ) : (
                                                lang.create
                                            )
                                        ) : (
                                            <CircularProgress color="info" size={24} />
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="form-wrapper">
                            <Grid container justifyItems="flex-start" spacing={2}>
                                {multiConfig && renderMultiSection()}
                                {formItems.map((formItem, ix) => (
                                    <Grid
                                        key={ix}
                                        item
                                        xs={(formItem.xs || 6) as GridSize}
                                        md={(formItem.md || 6) as GridSize}
                                    >
                                        {renderItem(formItem)}
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </form>
                </Paper>
            </Slide>
        </Modal>
    );
};

export { FormModal };
