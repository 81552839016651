import {
  Print,
  SaveAlt,
  WhatsApp,
  Twitter,
  WindowOutlined,
} from "@mui/icons-material";
import { IconButton, Modal, Paper, Slide, Stack } from "@mui/material";
import React, { FC, useRef } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import "./ShareQRModal.scss";

interface ShareQRModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const downloadQR = () => {
  const svg = document.getElementById("qrcode") as HTMLElement;
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  const img = new Image();
  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL("image/png");

    const downloadLink = document.createElement("a");
    downloadLink.download = "qrcode";
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  img.src = "data:image/svg+xml;base64," + btoa(svgData);
};

const redirectToWhatsApp = () => {
  window.location.href = "https://wa.me/?text=" + window.location.href;
};

const redirectToTwitter = () => {
  window.location.href =
    "http://twitter.com/share?text=" + window.location.href;
};

const ShareQRModal: FC<ShareQRModalProps> = ({ isOpen, onClose }) => {
  const printRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
  });

  return (
    <Modal className="qr-modal" open={isOpen} onClose={onClose}>
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <Paper sx={{ padding: "8px" }}>
          <span ref={printRef}>
            <QRCode id="qrcode" value={window.location.href} />
          </span>
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton onClick={handlePrint}>
              <Print />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton onClick={downloadQR}>
              <SaveAlt />
            </IconButton>
            <IconButton onClick={redirectToWhatsApp}>
              <WhatsApp />
            </IconButton>
            <IconButton onClick={redirectToTwitter}>
              <Twitter />
            </IconButton>
          </Stack>
        </Paper>
      </Slide>
    </Modal>
  );
};

export { ShareQRModal };
