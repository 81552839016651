import { useAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Lang, languageAtom } from '../../state/controls';
import { ListPage } from '../../components/common/ListPage/ListPage';
import { FieldItem, FormItem, FormModal, UploadedFile } from '../../components/common/FormModal/FormModal';
import { addDoc, collection, FieldValue, getDocs, query, where } from '@firebase/firestore';
import { firestore } from '../../firebase';
import { toast } from 'react-toastify';
import { Realtor } from '../RealtorProfile/RealtorProfile';
import { PageLoader } from '../../components/common/PageLoader/PageLoader';
import { ElementCard } from '../../components/common/ElementCard/ElementCard';
import { Project } from '../Projects/Projects';
import { useUserRole } from '../../hooks/useUserRole';

export interface Network {
  id: string;
  name: string;
  projects: string[];
  realtors: string[];
  createDate: Date;
  updateDate: Date;
  logo: UploadedFile[];
}

export const networkFormItems = (
  lang: Lang,
  projectItems: FieldItem[],
  realtorItems: FieldItem[],
  defaultProjects?: FieldItem[],
  defaultRealtors?: FieldItem[]
): FormItem[] => [
  {
    name: 'name',
    label: lang.name,
    type: 'text',
    required: true,
  },
  {
    name: 'description',
    label: lang.description,
    type: 'text',
    required: true,
  },
  {
    name: 'projects',
    label: lang.projects,
    type: 'autocomplete',
    multiple: true,
    required: true,
    items: projectItems,
    defaultValue: defaultProjects,
    xs: 12,
    md: 12,
  },
  {
    name: 'realtors',
    label: lang.realtors,
    type: 'autocomplete',
    multiple: true,
    required: true,
    items: realtorItems,
    defaultValue: defaultRealtors,
    xs: 12,
    md: 12,
  },
  {
    name: 'logo',
    label: lang.logo,
    type: 'image',
    required: true,
  },
];

const Networks: FC = () => {
  const [{ lang }] = useAtom(languageAtom);
  const [addNetworkModalOpen, setAddNetworkModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [realtors, setRealtors] = useState<Realtor[]>([]);
  const { isAdmin, isDeveloper, fbId } = useUserRole();

  const fetchOptions = useCallback(async () => {
    setIsFetching(true);

    const q = query(collection(firestore, 'projects'));
    const docs: Project[] = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id } as Project);
    });

    const qRealtors = query(collection(firestore, 'realtors'));
    const docsRealtors: Realtor[] = [];
    const querySnapshotRealtors = await getDocs(qRealtors);

    querySnapshotRealtors.forEach((doc) => {
      docsRealtors.push({ ...doc.data(), id: doc.id } as Realtor);
    });

    setProjects(docs);
    setRealtors(docsRealtors);

    setIsFetching(false);
  }, [setIsFetching]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const onSubmit = async (data: Record<string, FieldValue>) => {
    await addDoc(collection(firestore, 'networks'), { ...data, developerId: fbId });

    toast.success('Network added');
    return Promise.resolve();
  };

  const networkContentConfig = (network: Network) => [
    { label: lang.name, value: network.name },
    { label: lang.projects, value: network.projects?.length || 0 },
    { label: lang.realtors, value: network.realtors?.length || 0 },
  ];

  return (
    <div style={{ height: '100%' }}>
      {!isFetching ? (
        <>
          <ListPage
            actionText={lang.new_network}
            onActionBtnClick={() => setAddNetworkModalOpen(true)}
            collectionName='networks'
            queryConstraint={isDeveloper ? where('developerId', '==', fbId) : undefined}
            actionAllowed={isAdmin || isDeveloper}
            renderElement={(element, highlight) => {
              const network = element as unknown as Network;

              return (
                <ElementCard
                  small
                  element={{
                    linkPath: 'networks',
                    id: network.id,
                    content: networkContentConfig(network),
                    pics: network.logo,
                  }}
                  highlight={highlight}
                />
              );
            }}
          />
          <FormModal
            collectionName='networks'
            title={lang.network_form}
            formItems={networkFormItems(
              lang,
              projects.map(({ id, name }) => ({ label: name, value: id })),
              realtors.map(({ id, name }) => ({ label: name, value: id }))
            )}
            isOpen={addNetworkModalOpen}
            onClose={() => setAddNetworkModalOpen(false)}
            onSubmit={onSubmit}
          />
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export { Networks };
